import React from 'react';
import { Table, Button, Message, Confirm } from 'semantic';
import { formatDateTime } from 'utils/date';
import { request } from 'utils/api';
import screen from 'helpers/screen';
import { HelpTip, Breadcrumbs, SearchProvider, Layout } from 'components';
import { Link } from 'react-router-dom';

import Filters from 'modals/Filters';
import EditCuratedList from '../../../modals/EditCuratedList';
import { Image } from '../../../semantic';
import { urlForUpload } from '../../../utils/uploads';
import { withLoadedSession } from 'stores';
import { userHasAccess } from 'utils/permissions';
import { visibilityOptions } from '../../../utils/categories';

@screen
@withLoadedSession
export default class CuratedList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      products: [],
      cocktails: [],
    };
  }

  async componentDidMount() {
    const { data: products } = await request({
      method: 'POST',
      path: `/1/products/search`,
      body: {
        includeUpsell: true,
      },
    });

    const { data: cocktails } = await request({
      method: 'POST',
      path: `/1/cocktails/search`,
    });

    this.setState({ products, cocktails });
  }

  onDataNeeded = async (params) => {
    return await request({
      method: 'POST',
      path: '/1/curated-lists/search',
      body: params,
    });
  };

  render() {
    const { products, cocktails } = this.state;

    const writeAccess = userHasAccess(this.context.user, {
      endpoint: 'curatedLists',
      permission: 'write',
      scope: 'global',
    });

    return (
      <SearchProvider onDataNeeded={this.onDataNeeded}>
        {({
          items: curatedLists,
          getSorted,
          setSort,
          filters,
          setFilters,
          reload,
        }) => {
          return (
            <React.Fragment>
              <Breadcrumbs active="curated-lists" />
              <Layout horizontal center spread>
                <h1>Curated Lists</h1>
                <Layout.Group>
                  <Filters onSave={setFilters} filters={filters}>
                    <Filters.Text name="title" label="Title" />
                    <Filters.Text name="subtitle" label="Title" />
                    <Filters.Dropdown
                      search
                      name="type"
                      label="Type"
                      options={[
                        {
                          text: 'Cocktail',
                          value: 'cocktail',
                        },
                        {
                          text: 'Product',
                          value: 'product',
                        },
                      ]}
                    />
                    <Filters.Dropdown
                      search
                      name="products"
                      label="Product"
                      options={products.map((product) =>
                        Object({
                          text: product.name,
                          value: product.id,
                        })
                      )}
                    />
                    <Filters.Dropdown
                      search
                      name="visibility"
                      label="Visibility"
                      options={visibilityOptions}
                    />
                  </Filters>
                  <EditCuratedList
                    products={products}
                    cocktails={cocktails}
                    trigger={
                      <Button
                        primary
                        content="New Curated List"
                        icon="plus"
                        disabled={!writeAccess}
                      />
                    }
                    onSave={reload}
                  />
                </Layout.Group>
              </Layout>
              {curatedLists.length === 0 ? (
                <Message>No lists created yet</Message>
              ) : (
                <Table celled sortable>
                  <Table.Header>
                    <Table.Row>
                      <Table.HeaderCell>Image</Table.HeaderCell>
                      <Table.HeaderCell>Title</Table.HeaderCell>
                      <Table.HeaderCell>Subtitle</Table.HeaderCell>
                      <Table.HeaderCell>Type</Table.HeaderCell>
                      <Table.HeaderCell
                        onClick={() => setSort('createdAt')}
                        sorted={getSorted('createdAt')}>
                        Created
                        <HelpTip
                          title="Created"
                          text="This is the date and time the post was created."
                        />
                      </Table.HeaderCell>
                      <Table.HeaderCell textAlign="center">
                        Actions
                      </Table.HeaderCell>
                    </Table.Row>
                  </Table.Header>
                  <Table.Body>
                    {curatedLists.map((list) => {
                      return (
                        <Table.Row key={list.id}>
                          <Table.Cell>
                            {list.image && (
                              <Image
                                size="tiny"
                                src={urlForUpload(list.image, true)}
                              />
                            )}
                          </Table.Cell>
                          <Table.Cell>
                            <Link to={`/curated-lists/${list.id}`}>
                              {list.title}
                            </Link>
                          </Table.Cell>
                          <Table.Cell>{list.subtitle}</Table.Cell>
                          <Table.Cell>{list.type}</Table.Cell>
                          <Table.Cell>
                            {formatDateTime(list.createdAt)}
                          </Table.Cell>
                          <Table.Cell textAlign="center" singleLine>
                            <EditCuratedList
                              products={products}
                              cocktails={cocktails}
                              curatedList={list}
                              trigger={
                                <Button
                                  basic
                                  icon="edit"
                                  disabled={!writeAccess}
                                />
                              }
                              onSave={reload}
                            />
                            <Confirm
                              negative
                              confirmButton="Delete"
                              header={`Are you sure you want to delete "${list.title}"?`}
                              content="All data will be permanently deleted"
                              trigger={
                                <Button
                                  basic
                                  icon="trash"
                                  disabled={!writeAccess}
                                />
                              }
                              onConfirm={async () => {
                                await request({
                                  method: 'DELETE',
                                  path: `/1/curated-lists/${list.id}`,
                                });
                                reload();
                              }}
                            />
                          </Table.Cell>
                        </Table.Row>
                      );
                    })}
                  </Table.Body>
                </Table>
              )}
            </React.Fragment>
          );
        }}
      </SearchProvider>
    );
  }
}
