import GETTING_STARTED from './GETTING_STARTED.md';
import AUTHENTICATION from './AUTHENTICATION.md';
import PRODUCTS from './PRODUCTS.md';
import PRODUCT_VARIANTS from './PRODUCT_VARIANTS.md';
import INVENTORIES from './INVENTORIES.md';
import COCKTAILS from './COCKTAILS.md';
import POSTS from './POSTS.md';
import LOCATIONS from './LOCATIONS.md';
import USERS from './USERS.md';
import PAYMENT_METHODS from './PAYMENT_METHODS.md';
import SHIPPING_ADDRESSES from './SHIPPING_ADDRESSES.md';
import CART_ITEMS from './CART_ITEMS.md';
import ORDERS from './ORDERS.md';
import REVIEWS from './REVIEWS.md';
import UPLOADS from './UPLOADS.md';
import SURVEY_QUESTIONS from './SURVEY_QUESTIONS.md';
import SURVEY_RESPONSES from './SURVEY_RESPONSES.md';

export default {
  GETTING_STARTED,
  AUTHENTICATION,
  PRODUCTS,
  PRODUCT_VARIANTS,
  INVENTORIES,
  COCKTAILS,
  POSTS,
  LOCATIONS,
  USERS,
  PAYMENT_METHODS,
  SHIPPING_ADDRESSES,
  CART_ITEMS,
  ORDERS,
  REVIEWS,
  UPLOADS,
  SURVEY_QUESTIONS,
  SURVEY_RESPONSES,
};
