import React from 'react';
import { Icon } from 'semantic';

import bem from 'helpers/bem';

import { toggleBlockType } from '../utils';
import { BLOCK_OPTIONS } from '../const';
import Menu from '../Menu';

class RichTextEditorAlignmentMenu extends React.Component {
  render() {
    return (
      <Menu
        trigger={
          <Icon name="block-style custom" style={{ marginRight: '4px' }} />
        }>
        {Object.values(BLOCK_OPTIONS).map(({ text, value }) => {
          return (
            <Menu.Item
              key={value}
              onClick={() => {
                const { editorState, updateState } = this.context;
                updateState(toggleBlockType(editorState, value));
              }}>
              <div style={{ padding: '10px 20px' }}>{text}</div>
            </Menu.Item>
          );
        })}
      </Menu>
    );
  }
}

export default bem(RichTextEditorAlignmentMenu);
