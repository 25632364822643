import React from 'react';
import { Table, Divider, Image } from 'semantic';
import screen from 'helpers/screen';
import Menu from './Menu';

import { formatDateTime } from 'utils/date';
import { urlForUpload } from 'utils/uploads';
import {
  cocktailCategoryOptions,
  cocktailStyleOptions,
  formatOption
} from 'utils/categories';
import { Link } from 'react-router-dom';

@screen
export default class CocktailOverview extends React.Component {
  render() {
    const { cocktail } = this.props;
    return (
      <React.Fragment>
        <Menu {...this.props} />
        <Divider hidden />
        <Image.Group size="small">
          {cocktail.images.map(image => <Image key={image.id} src={urlForUpload(image)} />)}

          { cocktail.thumbnailSmall && (
            <div>
              <h4>Thumbnail Small</h4>
              <Image src={urlForUpload(cocktail.thumbnailSmall)} />
            </div>
          )}

          { cocktail.thumbnailLarge && (
            <div>
              <h4>Thumbnail Large</h4>
              <Image src={urlForUpload(cocktail.thumbnailLarge)} />
            </div>
          )}
        </Image.Group>

        <Table definition>
          <Table.Body>
            <Table.Row>
              <Table.Cell>Theme</Table.Cell>
              <Table.Cell>{cocktail.theme || 'None'}</Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>Spirits</Table.Cell>
              <Table.Cell>{cocktail.spirits.join(', ') || 'None'}</Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>Description</Table.Cell>
              <Table.Cell>{cocktail.description || 'None'}</Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>Visibility</Table.Cell>
              <Table.Cell>{cocktail.visibility || 'None'}</Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>Is Featured</Table.Cell>
              <Table.Cell>{cocktail.isFeatured ? 'Yes' : 'No'}</Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>Category</Table.Cell>
              <Table.Cell>
                {formatOption(cocktailCategoryOptions, cocktail.category)}
              </Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>Style</Table.Cell>
              <Table.Cell>
                {formatOption(cocktailStyleOptions, cocktail.style)}
              </Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>Flavor</Table.Cell>
              <Table.Cell>{cocktail.flavor.join(', ') || 'None'}</Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>Intensity Percentage</Table.Cell>
              <Table.Cell>{cocktail.intensityPercentage || 'None'}</Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>Recipe Duration Minutes</Table.Cell>
              <Table.Cell>
                {cocktail.recipeDurationMinutes || 'None'}
              </Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>Products</Table.Cell>
              <Table.Cell>
                { cocktail.products.length > 0 && cocktail.products.map((p, i) => {
                  const { product, productVariant } = p;

                  return (
                    <React.Fragment key={i}>
                      <strong>Product: </strong>
                      <Link to={`/products/${product.id}`}>{ product.name }</Link>
                      <br />
                      <strong>Variant: </strong>
                      <span>{ productVariant.liquidAmount } { productVariant.liquidAmountUnit }</span>
                      <Divider />
                    </React.Fragment>
                  );
                }) }
              </Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>Author Name</Table.Cell>
              <Table.Cell>{cocktail.authorName || 'None'}</Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>Author Social URL</Table.Cell>
              <Table.Cell>{cocktail.authorSocialUrl || 'None'}</Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>Author Image</Table.Cell>
              <Table.Cell>{cocktail.authorImage || 'None'}</Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>Created At</Table.Cell>
              <Table.Cell>{formatDateTime(cocktail.createdAt)}</Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>Updated At</Table.Cell>
              <Table.Cell>{formatDateTime(cocktail.updatedAt)}</Table.Cell>
            </Table.Row>
          </Table.Body>
        </Table>
      </React.Fragment>
    );
  }
}
