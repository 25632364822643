import React from 'react';
import { Link } from 'react-router-dom';
import { Menu, Button } from 'semantic';
import { NavLink } from 'react-router-dom';
import { Breadcrumbs, Layout } from 'components';

import EditPost from 'modals/EditPost';

export default ({ post, onSave }) => {
  return (
    <React.Fragment>
      <Breadcrumbs
        link={<Link to="/posts">Posts</Link>}
        active={post.name}></Breadcrumbs>
      <Layout horizontal center spread>
        <h1>{post.name}</h1>
        <Layout.Group>
          <EditPost
            post={post}
            onSave={onSave}
            trigger={<Button primary icon="setting" content="Settings" />}
          />
        </Layout.Group>
      </Layout>
      <Menu pointing secondary>
        <Menu.Item
          name="Overview"
          to={`/posts/${post.id}`}
          as={NavLink}
          exact
        />

      </Menu>
    </React.Fragment>
  );
};
