import React from 'react';
import { Table, Button, Message, Confirm } from 'semantic';
import { formatDateTime } from 'utils/date';
import { request } from 'utils/api';
import screen from 'helpers/screen';
import { HelpTip, Breadcrumbs, SearchProvider, Layout } from 'components';
import { Link } from 'react-router-dom';

import Filters from 'modals/Filters';
import EditPost from 'modals/EditPost';
import { withLoadedSession } from 'stores';
import { userHasAccess } from 'utils/permissions';
import { visibilityOptions } from '../../../utils/categories';

@screen
@withLoadedSession
export default class PostList extends React.Component {
  onDataNeeded = async (params) => {
    return await request({
      method: 'POST',
      path: '/1/posts/search',
      body: params,
    });
  };

  render() {
    const writeAccess = userHasAccess(this.context.user, {
      endpoint: 'posts',
      permission: 'write',
      scope: 'global',
    });
    return (
      <SearchProvider onDataNeeded={this.onDataNeeded}>
        {({
          items: posts,
          getSorted,
          setSort,
          filters,
          setFilters,
          reload,
        }) => {
          return (
            <React.Fragment>
              <Breadcrumbs active="Posts" />
              <Layout horizontal center spread>
                <h1>Posts</h1>
                <Layout.Group>
                  <Filters onSave={setFilters} filters={filters}>
                    <Filters.Text name="title" label="Title" />
                    <Filters.Dropdown
                      search
                      name="type"
                      label="Type"
                      options={[
                        {
                          text: 'video',
                          value: 'video',
                        },
                        {
                          text: 'drink-experience',
                          value: 'drink-experience',
                        },
                        {
                          text: 'article',
                          value: 'article',
                        },
                        {
                          text: 'podcast',
                          value: 'podcast',
                        },
                        {
                          text: 'playlist',
                          value: 'playlist',
                        },
                      ]}
                    />
                    <Filters.Dropdown
                      search
                      name="drinkExperienceType"
                      label="Drink Experience Type"
                      options={[
                        {
                          text: 'music',
                          value: 'music',
                        },
                        {
                          text: 'movies',
                          value: 'movies',
                        },
                        {
                          text: 'social',
                          value: 'social',
                        },
                        {
                          text: 'meals',
                          value: 'meals',
                        },
                      ]}
                    />
                    <Filters.Dropdown
                      search
                      multiple
                      allowAdditions
                      name="spirits"
                      label="Spirits"
                    />
                    <Filters.Text name="videosTitle" label="Videos Title" />
                    <Filters.Text name="productsTitle" label="Products Title" />
                    <Filters.Text
                      name="relatedPostsTitle"
                      label="Related Posts Title"
                    />
                    <Filters.Dropdown
                      search
                      name="visibility"
                      label="Visibility"
                      options={visibilityOptions}
                    />
                    <Filters.Checkbox name="isFeatured" label="Is Featured" />
                  </Filters>
                  <EditPost
                    trigger={
                      <Button
                        primary
                        content="New Post"
                        icon="plus"
                        disabled={!writeAccess}
                      />
                    }
                    onSave={reload}
                  />
                </Layout.Group>
              </Layout>
              {posts.length === 0 ? (
                <Message>No posts created yet</Message>
              ) : (
                <Table celled sortable>
                  <Table.Header>
                    <Table.Row>
                      <Table.HeaderCell>Title</Table.HeaderCell>
                      <Table.HeaderCell
                        onClick={() => setSort('createdAt')}
                        sorted={getSorted('createdAt')}>
                        Created
                        <HelpTip
                          title="Created"
                          text="This is the date and time the post was created."
                        />
                      </Table.HeaderCell>
                      <Table.HeaderCell>Type</Table.HeaderCell>
                      <Table.HeaderCell textAlign="center">
                        Actions
                      </Table.HeaderCell>
                    </Table.Row>
                  </Table.Header>
                  <Table.Body>
                    {posts.map((post) => {
                      return (
                        <Table.Row key={post.id}>
                          <Table.Cell>
                            <Link to={`/posts/${post.id}`}>{post.title}</Link>
                          </Table.Cell>
                          <Table.Cell>
                            {formatDateTime(post.createdAt)}
                          </Table.Cell>
                          <Table.Cell>{post.type}</Table.Cell>
                          <Table.Cell textAlign="center" singleLine>
                            <EditPost
                              post={post}
                              trigger={
                                <Button
                                  basic
                                  icon="edit"
                                  disabled={!writeAccess}
                                />
                              }
                              onSave={reload}
                            />
                            <Confirm
                              negative
                              confirmButton="Delete"
                              header={`Are you sure you want to delete "${post.name}"?`}
                              content="All data will be permanently deleted"
                              trigger={
                                <Button
                                  basic
                                  icon="trash"
                                  disabled={!writeAccess}
                                />
                              }
                              onConfirm={async () => {
                                await request({
                                  method: 'DELETE',
                                  path: `/1/posts/${post.id}`,
                                });
                                reload();
                              }}
                            />
                          </Table.Cell>
                        </Table.Row>
                      );
                    })}
                  </Table.Body>
                </Table>
              )}
            </React.Fragment>
          );
        }}
      </SearchProvider>
    );
  }
}
