import React from 'react';
import { Header, Table, Loader } from 'semantic';
import screen from 'helpers/screen';

import Menu from './Menu';

@screen
export default class Preferences extends React.Component {
  render() {
    const { user } = this.props;
    return (
      <React.Fragment>
        <Menu {...this.props} />
        {!user ? (
          <Loader active>Loading</Loader>
        ) : (
          <React.Fragment>
            <Header as="h3">Preferences</Header>
            { user.preferences.length > 0 ?
                <Table celled>
                  <Table.Header>
                    <Table.Row>
                      <Table.HeaderCell>Question</Table.HeaderCell>
                      <Table.HeaderCell>Answer</Table.HeaderCell>
                    </Table.Row>
                  </Table.Header>

                  <Table.Body>
                    {user.preferences.map((preference, i) => {
                      const { question, answer } = preference;
                      return (
                        <Table.Row key={i}>
                          <Table.Cell>{ question }</Table.Cell>
                          <Table.Cell>{ answer }</Table.Cell>
                        </Table.Row>
                      );
                    })}
                  </Table.Body>
                </Table>
              : <p>No Preferences selected yet.</p>
            }
          </React.Fragment>
        )}
      </React.Fragment>
    );
  }
}
