import React from 'react';
import { Table, Header, Divider } from 'semantic';
import screen from 'helpers/screen';
import Menu from './Menu';
import { Link } from 'react-router-dom';

import { formatDateTime } from 'utils/date';
import OrderItems from './OrderItems';
import CourrierStatus from './CourrierStatus';
import { formatDeliveryStatus, formatPickupStatus } from 'utils/formatting';

@screen
export default class OrderOverview extends React.Component {
  render() {
    const { order } = this.props;
    return (
      <React.Fragment>
        <Menu {...this.props} />
        <Divider hidden />
        {order.deliveryType === 'courrier' && (
          <React.Fragment>
            <Header as="h3">Courrier Status</Header>
            <Divider hidden />
            <CourrierStatus order={order} />
            <Divider hidden />
          </React.Fragment>
        )}
        <Header as="h3">Order Summary</Header>
        <Divider hidden />
        <OrderItems order={order} />
        <Divider hidden />
        <Header as="h3">Order Details</Header>
        <Table definition collapsing>
          <Table.Body>
            <Table.Row>
              <Table.Cell>User</Table.Cell>
              <Table.Cell>
                {order.user && (
                  <Link to={`/users/${order.user.id}`}>
                    {order.user.firstName} {order.user.lastName}
                  </Link>
                )}
              </Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>Status</Table.Cell>
              <Table.Cell>
                {order.deliveryType === 'courrier' &&
                  formatDeliveryStatus(order.deliveryStatus)}
                {order.deliveryType === 'pickup' &&
                  formatPickupStatus(order.pickupStatus)}
              </Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>Delivery Type</Table.Cell>
              <Table.Cell>{order.deliveryType || 'None'}</Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>Currency</Table.Cell>
              <Table.Cell>{order.currency || 'None'}</Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>Created At</Table.Cell>
              <Table.Cell>{formatDateTime(order.createdAt)}</Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>Updated At</Table.Cell>
              <Table.Cell>{formatDateTime(order.updatedAt)}</Table.Cell>
            </Table.Row>
          </Table.Body>
        </Table>
      </React.Fragment>
    );
  }
}
