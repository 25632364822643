import React from 'react';
import { Label } from 'semantic';

export const categoryOptions = [
  { value: 'new', text: 'New & Noteworthy' },
  { value: 'everyday-classics', text: 'Everyday Classics' },
  { value: 'canned-and-bottled', text: 'Canned and Bottled' },
  { value: 'black-owned', text: 'Black Owned Business' },
  { value: 'women-owned', text: 'Women Owned Business' },
  { value: 'locally-sourced', text: 'Locally Sourced' },
  { value: 'organic', text: 'Organic' },
];

export const cocktailCategoryOptions = [
  {
    value: 'cocktail-kit',
    text: 'Cocktail Kit',
  },
  {
    value: 'diy',
    text: 'DIY',
  },
];

export const cocktailStyleOptions = [
  {
    value: 'classic',
    text: 'Classic',
  },
  {
    value: 'seasonal',
    text: 'Seasonal',
  },
  {
    value: 'what-we-are-drinking-now',
    text: "What We're Drinking Now",
  },
  {
    value: 'hb-crew-mix',
    text: 'Hb Crew Mix',
  },
];

export const productTypeOptions = [
  { value: 'spirit', text: 'Spirit' },
  { value: 'wine', text: 'Wine' },
  { value: 'beer-ciders-seltzer', text: 'Beer, Ciders, & Seltzer' },
  { value: 'non-alcoholic', text: 'Non Alcoholic' },
  { value: 'drinks-sodas-mixers-ice', text: 'Drinks, Sodas, Mixers and Ice' },
  {
    value: 'bites-snacks-sweets-ice-cream',
    text: 'Bites, Snacks, Sweets and Ice Cream',
  },
  {
    value: 'home-and-bar',
    text: 'Home and Bar',
  },
];

export const themeOptions = [
  { text: 'Gin', value: 'gin' },
  { text: 'Rum', value: 'rum' },
  { text: 'Agave', value: 'agave' },
  { text: 'Vodka', value: 'vodka' },
  { text: 'Whiskey', value: 'whiskey' },
];

export const cocktailDifficultyOptions = [
  { text: 'Easy', value: 'easy' },
  { text: 'Advanced', value: 'advanced' },
  { text: 'Moderate', value: 'moderate' },
  { text: 'Hard', value: 'hard' },
  { text: 'Expert', value: 'export' },
];

export const visibilityOptions = [
  { text: 'draft', value: 'draft' },
  { text: 'published', value: 'published' },
];

export const postLinkSourceOptions = [
  { text: 'Hulu', value: 'hulu' },
  { text: 'Netflix', value: 'netflix' },
  { text: 'Soundcloud', value: 'soundcloud' },
  { text: 'YouTube', value: 'youtube' },
  { text: 'Apple Music', value: 'apple-music' },
  { text: 'Spotify', value: 'spotify' },
  { text: 'Prime', value: 'prime' },
];

/**
 * Returns array of options based on given product type
 * @param type {'spirit' | 'beer-ciders-seltzer' | 'wine' | 'non-alcoholic' | 'drinks-sodas-mixers-ice' | 'bites-snacks-sweets-ice-cream'} Product Type
 */
export function spiritsByType(type) {
  return window.meta.spiritTypes[type];
}

export function formatOption(options, item) {
  const option = options.filter((o) => o.value === item)[0];
  if (!option) {
    return '-';
  }
  return option.text;
}

export function renderCocktailFlags(cocktail) {
  const flags = [];
  if (cocktail.isFeatured) {
    flags.push(<Label content="Featured" key="featured" color="teal" />);
  }
  if (cocktail.isFeaturedForWeek) {
    flags.push(
      <Label content="Cocktail of the Week" key="featured" color="teal" />
    );
  }
  return flags;
}

export function renderProductFlags(product) {
  const flags = [];
  if (product.isFeatured) {
    flags.push(<Label content="Featured" key="featured" color="teal" />);
  }
  if (product.isUpsell) {
    flags.push(<Label content="Upsell" key="featured" />);
  }
  return flags;
}
