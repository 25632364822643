import React from 'react';
import { Form, Message, Button, Loader, Header, Segment } from 'semantic';

import { request } from 'utils/api';
import QRCode from 'components/QRCode';
import { ExternalLink } from 'components/Link';
import { Layout } from 'components';

import screen from 'helpers/screen';
import PageCenter from 'components/PageCenter';
import { Link } from 'react-router-dom';
import LogoTitle from 'components/LogoTitle';
import Code from 'components/form-fields/Code';

import Finalize from './Finalize';

@screen
export default class Authenticator extends React.Component {
  static layout = 'none';

  state = {
    loading: false,
    error: null,
    secret: null,
    code: '',
    secretUri: null,
  };

  componentDidMount() {
    this.fetchSecret();
  }

  async fetchSecret() {
    try {
      const { data } = await request({
        method: 'POST',
        path: '/1/mfa/setup',
        body: {
          method: 'otp',
        },
      });

      this.setState({
        secret: data.secret,
        secretUri: data.uri,
        loading: false,
      });
    } catch (error) {
      if (error.status == 403) {
        this.props.history.push(
          '/confirm-access?to=/settings/mfa-authenticator'
        );
        return;
      }

      this.setState({
        error,
        loading: false,
      });
    }
  }

  onSubmit = async () => {
    this.setState({
      loading: true,
    });

    try {
      await request({
        method: 'POST',
        path: '/1/mfa/check-code',
        body: {
          code: this.state.code,
          secret: this.state.secret,
          method: 'otp',
        },
      });

      const { data } = await request({
        method: 'POST',
        path: '/1/mfa/generate-backup-codes',
      });

      this.setState({
        verified: true,
        codes: data,
      });
    } catch (error) {
      this.setState({
        error,
        loading: false,
      });
    }
  };

  render() {
    const { code, codes, loading, error, secretUri, secret, verified } =
      this.state;

    if (verified) {
      return (
        <Finalize
          codes={codes}
          requestBody={{
            secret,
            method: 'otp',
            backupCodes: codes,
          }}
        />
      );
    }

    return (
      <PageCenter>
        <LogoTitle title="Set up app authentication" />

        <Segment.Group>
          <Segment>
            <Header size="small">1. Download an authenticator app.</Header>
            <p>
              We recommend{' '}
              <ExternalLink href="https://support.google.com/accounts/answer/1066447">
                Google Authenticator
              </ExternalLink>
              ,{' '}
              <ExternalLink href="https://support.1password.com/one-time-passwords/">
                1Password
              </ExternalLink>
              , or <ExternalLink href="https://authy.com/">Authy.</ExternalLink>
            </p>
          </Segment>
          <Segment style={{ minHeight: '276px' }}>
            <Header size="small">
              2. Scan this barcode using your authenticator app
            </Header>
            <Layout center>
              {!secretUri && <Loader active />}

              {secretUri && (
                <QRCode
                  style={{ display: 'block', marginTop: '1em' }}
                  data={secretUri}
                />
              )}
            </Layout>
          </Segment>
          <Form onSubmit={this.onSubmit}>
            <Segment>
              <Header size="small">
                3. Enter the security code generated by your app
              </Header>
              {error && <Message error content={error.message} />}
              <Layout center>
                <Code
                  className="verification-code"
                  type="number"
                  fields={6}
                  autoFocus
                  onChange={(value) => this.setState({ code: value })}
                  onComplete={(value) => {
                    this.setState({ code: value }, () => {
                      this.onSubmit();
                    });
                  }}
                />
              </Layout>
            </Segment>
            <Segment>
              <Button
                type="submit"
                primary
                loading={loading}
                disabled={loading || code.length !== 6}
                content={'Verify'}
              />
              <Button
                as={Link}
                to="/settings/security"
                basic
                floated="right"
                secondary
                content={'Cancel'}
              />
            </Segment>
          </Form>
        </Segment.Group>
      </PageCenter>
    );
  }
}
