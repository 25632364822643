import React from 'react';
import { Link } from 'react-router-dom';
import { Menu, Button } from 'semantic';
import { NavLink } from 'react-router-dom';
import { Breadcrumbs, Layout } from 'components';

import EditProduct from 'modals/EditProduct';

export default ({ product, products, onSave }) => {
  return (
    <React.Fragment>
      <Breadcrumbs
        link={<Link to="/products">Products</Link>}
        active={product.name}
      />
      <Layout horizontal center spread>
        <h1>{product.name}</h1>
        <Layout.Group>
          {product.squareReference && (
            <Button
              basic
              icon="external-link-alt"
              content="View on Square"
              as="a"
              href={`https://squareup.com/dashboard/items/library/${product.squareReference}`}
            />
          )}
          <EditProduct
            product={product}
            products={products}
            onSave={onSave}
            trigger={<Button primary icon="setting" content="Settings" />}
          />
        </Layout.Group>
      </Layout>
      <Menu pointing secondary>
        <Menu.Item
          name="Overview"
          to={`/products/${product.id}`}
          as={NavLink}
          exact
        />
        <Menu.Item
          name="Variants"
          to={`/products/${product.id}/variants`}
          as={NavLink}
          exact
        />
        <Menu.Item
          name="Inventory"
          to={`/products/${product.id}/inventory`}
          as={NavLink}
          exact
        />
      </Menu>
    </React.Fragment>
  );
};
