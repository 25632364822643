import React, {useState} from 'react';
import { set } from 'lodash';
import { Modal, Form, Button, Message } from 'semantic';
import { request } from 'utils/api';
import AutoFocus from 'components/AutoFocus';
import modal from 'helpers/modal';

import UploadsField from 'components/form-fields/Uploads';
import ArrayField from 'components/form-fields/Array';
import RichTextField from '../components/form-fields/RichText';
import {
  cocktailCategoryOptions,
  cocktailDifficultyOptions,
  cocktailStyleOptions,
  spiritsByType,
  themeOptions, visibilityOptions,
} from 'utils/categories';
import ReferenceField from '../components/form-fields/Reference';

@modal
export default class EditCocktail extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      loading: false,
      cocktail: props.cocktail || {},
      products: props.products || [],
      productVariants: [],
    };
  }

  isUpdate() {
    return !!this.props.cocktail;
  }

  setField = (evt, { name, value }) => {
    const cocktail = { ...this.state.cocktail };
    set(cocktail, name, value);
    this.setState({
      cocktail,
    });
  };

  setNumberField = (evt, { name, value }) => {
    this.setField(evt, { name, value: Number(value) });
  };

  setCheckedField = (evt, { name, checked }) => {
    this.setField(evt, { name, value: checked });
  };

  onSubmit = async () => {
    this.setState({
      loading: true,
    });
    const { cocktail } = this.state;

    try {
      if (this.isUpdate()) {
        await request({
          method: 'PATCH',
          path: `/1/cocktails/${cocktail.id}`,
          body: cocktail,
        });
      } else {
        await request({
          method: 'POST',
          path: '/1/cocktails',
          body: {
            ...cocktail,
          },
        });
      }
      this.props.close();
      this.props.onSave();
    } catch (error) {
      this.setState({
        error,
        loading: false,
      });
    }
  };

  /**
   * Retrieves variants based on given product id
   * @param id {string} Product id
   * @returns {*[]|*} Array of ProductVariant objects
   */
  getVariants = (id) => {
    // const { products } = this.state;
    // const filteredProducts = products.filter(p => p.id === id);
    //
    // if (filteredProducts.length > 0) {
    //   return filteredProducts.pop().variants;
    // } else {
    //   return [];
    // }
    return request({
      method: 'POST',
      path: `/1/product-variants/search`,
      body: {
        product: id
      },
    })
    .then(({ data }) => data)
    .catch(() => []);
  };

  render() {
    const { cocktail, products, loading, error } = this.state;
    return (
      <React.Fragment>
        <Modal.Header>
          {this.isUpdate() ? `Edit "${cocktail.name}"` : 'New Cocktail'}
        </Modal.Header>
        <Modal.Content>
          <AutoFocus>
            <Form
              noValidate
              id="edit-cocktail"
              error={!!error}
              onSubmit={this.onSubmit}>
              {error && <Message error content={error.message} />}
              <Form.Input
                required
                type="text"
                name="name"
                label="Name"
                value={cocktail.name || ''}
                onChange={this.setField}
              />
              <Form.Dropdown
                selection
                required
                name="category"
                label="Category"
                value={cocktail.category || ''}
                options={cocktailCategoryOptions}
                onChange={this.setField}
              />
              <Form.Dropdown
                required
                selection
                name="theme"
                label="Theme"
                value={cocktail.theme || ''}
                options={themeOptions}
                onChange={this.setField}
              />
              <Form.Dropdown
                selection
                name="style"
                label="Style"
                value={cocktail.style || ''}
                options={cocktailStyleOptions}
                onChange={this.setField}
              />
              <UploadsField
                required
                name="backgroundImage"
                label="Hero Image"
                value={cocktail.backgroundImage || {}}
                single={true}
                onChange={this.setField}
                onError={(error) => this.setState({ error })}
              />
              <UploadsField
                required
                name="thumbnailSmall"
                label="Thumbnail Image (Small)"
                value={cocktail.thumbnailSmall || {}}
                single={true}
                onChange={this.setField}
                onError={(error) => this.setState({ error })}
              />
              <UploadsField
                required
                name="thumbnailLarge"
                label="Thumbnail Image (Large)"
                value={cocktail.thumbnailLarge || {}}
                single={true}
                onChange={this.setField}
                onError={(error) => this.setState({ error })}
              />
              <RichTextField
                required
                type="text"
                name="description"
                label="About"
                value={cocktail.description || ''}
                onChange={this.setField}
                short={true}
              />
              <RichTextField
                required
                type="text"
                name="bodyText"
                label="Cocktail Details"
                value={cocktail.bodyText || ''}
                onChange={this.setField}
                short={true}
              />
              <UploadsField
                required
                name="images"
                label="Secondary cocktails Images"
                value={cocktail.images || []}
                onChange={this.setField}
                onError={(error) => this.setState({ error })}
              />
              <Form.Dropdown
                search
                selection
                multiple
                allowAdditions
                name="flavor"
                label="Flavors"
                options={
                  cocktail.flavor?.map((value) => {
                    return {
                      value,
                      text: value,
                    };
                  }) || []
                }
                onChange={this.setField}
                value={cocktail.flavor || []}
              />
              <Form.Dropdown
                required
                selection
                name="difficulty"
                label="Difficulty"
                value={cocktail.difficulty || ''}
                options={cocktailDifficultyOptions}
                onChange={this.setField}
              />
              <Form.Input
                required
                name="intensityPercentage"
                label="Strength"
                type="number"
                min={1}
                max={5}
                step={1}
                onChange={this.setField}
                value={cocktail.intensityPercentage || 1}
                default={1}
              />
              <ArrayField
                label="Products"
                name="products"
                childLabel="Product"
                onChange={this.setField}
                value={cocktail.products || []}
                getNewField={() => Object({ product: '', productVariant: '' })}>
                {(cocktail.products || []).map((p, i) => {
                  const { product, productVariant } = p;

                  return (
                    <React.Fragment key={i}>
                      <ReferenceField
                        name={`products.${i}.product`}
                        label="Products"
                        value={product || null}
                        path="/1/products/search"
                        placeholder="Search Products"
                        onChange={this.setField}
                      />
                      { product && <ReferenceField
                        name={`products.${i}.productVariant`}
                        label="Product Variants"
                        value={productVariant || null}
                        path="/1/product-variants/search"
                        placeholder="Search Product Variants"
                        onChange={this.setField}
                      /> }
                    </React.Fragment>
                  );
                })}
              </ArrayField>
              <ArrayField
                label="Ingredients"
                name="ingredients"
                childLabel="Ingredient"
                onChange={this.setField}
                value={cocktail.ingredients || []}
                getNewField={() => Object({ caption: '', quantity: 1 })}>
                {(cocktail.ingredients || []).map((ingredient, i) => (
                  <IngredientField
                    key={i}
                    ingredient={ingredient}
                    index={i}
                    onChange={this.setField}
                    onNumberChange={this.setNumberField}
                  />
                ))}
              </ArrayField>
              <ArrayField
                label="Recipe Steps"
                name="recipeSteps"
                childLabel="Recipe Step"
                onChange={this.setField}
                value={cocktail.recipeSteps || []}
                getNewField={() => Object({ image: null, title: '', description: '' })}>
                {(cocktail.recipeSteps || []).map((step, i) => {
                  return (
                    <React.Fragment key={i}>
                      <Form.Input
                        label="Recipe step title"
                        name={`recipeSteps.${i}.title`}
                        value={step.title || ''}
                        onChange={this.setField}
                      />
                      <RichTextField
                        short={true}
                        label="Recipe step description"
                        name={`recipeSteps.${i}.description`}
                        value={step.description || ''}
                        onChange={this.setField}
                      />
                      <UploadsField
                        type="video"
                        name={`recipeSteps.${i}.video`}
                        label="Recipe step video"
                        value={step.video}
                        onChange={this.setField}
                        onError={(error) => this.setState({ error })}
                      />
                      <UploadsField
                        name={`recipeSteps.${i}.image`}
                        label="Recipe step image"
                        value={step.image || ''}
                        onChange={this.setField}
                        onError={(error) => this.setState({ error })}
                      />
                    </React.Fragment>
                  );
                })}
              </ArrayField>
              <Form.Dropdown
                required
                selection
                name="visibility"
                label="Visibility"
                value={cocktail.visibility || ''}
                options={visibilityOptions}
                onChange={this.setField}
              />
              <Form.Dropdown
                search
                selection
                multiple
                allowAdditions
                name="spirits"
                label="Spirits"
                options={spiritsByType('spirit')}
                onChange={this.setField}
                value={cocktail.spirits || ''}
              />
              <Form.Input
                type="text"
                name="authorName"
                label="Author Name/Handle"
                value={cocktail.authorName || ''}
                onChange={this.setField}
              />
              <UploadsField
                single={true}
                name="authorImage"
                label="Author Image"
                value={cocktail.authorImage || {}}
                onChange={this.setField}
                onError={(error) => this.setState({ error })}
              />
              <Form.Input
                type="url"
                name="authorSocialUrl"
                label="Author Social URL"
                value={cocktail.authorSocialUrl || ''}
                onChange={this.setField}
              />
              <Form.Checkbox
                name="isFeatured"
                label="Is Featured"
                checked={cocktail.isFeatured}
                onChange={this.setCheckedField}
              />
              <Form.Checkbox
                name="isFeaturedForWeek"
                label="Is Featured For Week"
                checked={cocktail.isFeaturedForWeek}
                onChange={this.setCheckedField}
              />
            </Form>
          </AutoFocus>
        </Modal.Content>
        <Modal.Actions>
          <Button
            primary
            form="edit-cocktail"
            loading={loading}
            disabled={loading}
            content={this.isUpdate() ? 'Update' : 'Create'}
          />
        </Modal.Actions>
      </React.Fragment>
    );
  }
}

/**
 * Renders a single ingredient section with form fields attributed to that ingredient
 * @param ingredient {Object} Ingredient object that comes from cocktail
 * @param index {Number} Iteration index to keep track of which ingredient to update in cocktail.ingredients array
 * @param onNumberChange {Function} Function reference to setNumberField in EditCocktail class
 * @param onChange {Function} Function reference to setField in EditCocktail class
 * @returns {JSX.Element}
 * @constructor
 */
const IngredientField = ({ ingredient, index, onNumberChange, onChange }) => {
  const [quantity, setQuantity] = useState(ingredient.quantity || 0);

  return (
    <React.Fragment>
      <Form.Input
        required
        label="Ingredient Name"
        name={`ingredients.${index}.caption`}
        value={ingredient.caption || ''}
        onChange={onChange}
      />
      <Form.Group widths="equal">
        <Form.Input
          type="number"
          name={`ingredients.${index}.quantity`}
          label="Ingredient Quantity"
          min={0}
          value={quantity}
          onChange={(event, context) => {
            setQuantity(Number(context.value));
            onNumberChange(event, context);
          }}
        />
        <Form.Dropdown
          selection
          name={`ingredients.${index}.quantityUnit`}
          label="Quantity Unit"
          placeholder="Select Unit"
          value={ingredient.quantityUnit || ''}
          options={[
            {
              text: 'Cups',
              value: 'cups',
            },
            {
              text: 'Tablespoons',
              value: 'tablespoons',
            },
            {
              text: 'Teaspoons',
              value: 'teaspoons',
            },
            {
              text: 'Grams',
              value: 'grams',
            },
            {
              text: 'Liquid Ounces',
              value: 'liquid-ounces',
            },
            {
              text: 'Ounces',
              value: 'ounces',
            },
            {
              text: 'Pinches',
              value: 'pinches',
            },
            {
              text: 'Dashes',
              value: 'dashes',
            },
          ]}
          onChange={onChange}
        />
      </Form.Group>
      { quantity === 0 &&
        <Form.Input
          type="text"
          name={`ingredients.${index}.garnish`}
          label="Garnish"
          value={ingredient.garnish || ''}
          onChange={onChange}
        />
      }
    </React.Fragment>
  );
};
