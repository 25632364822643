import React from 'react';
import { Table, Divider, Image } from 'semantic';
import screen from 'helpers/screen';
import Menu from './Menu';

import { formatDateTime } from 'utils/date';
import { urlForUpload } from 'utils/uploads';
import { formatOption, categoryOptions } from 'utils/categories';
import { Link } from 'react-router-dom';

@screen
export default class ProductOverview extends React.Component {
  render() {
    const { product } = this.props;
    return (
      <React.Fragment>
        <Menu {...this.props} />
        <Divider hidden />
        <Image.Group size="small">
          {product.images && (
            <React.Fragment>
              {product.images.map((image) => (
                <Image key={image.id} src={urlForUpload(image)} />
              ))}
            </React.Fragment>
          )}
        </Image.Group>
        <Table definition>
          <Table.Body>
            <Table.Row>
              <Table.Cell>Type</Table.Cell>
              <Table.Cell>{product.type || 'None'}</Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>Sub Type</Table.Cell>
              <Table.Cell>
                {formatOption(window.meta.subTypes, product.subType) || 'None'}
              </Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>Spirit</Table.Cell>
              <Table.Cell>{product.spirit || 'None'}</Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>Special Tag(s)</Table.Cell>
              <Table.Cell>
                {product.categories
                  .map((key) => formatOption(categoryOptions, key))
                  .join(', ')}
              </Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>Brand Name</Table.Cell>
              <Table.Cell>{product.brandName || 'None'}</Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>Description</Table.Cell>
              <Table.Cell>{product.description || 'None'}</Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>Why We Dig It</Table.Cell>
              <Table.Cell>{product.whyWeDigIt || 'None'}</Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>Related Products</Table.Cell>
              <Table.Cell>
                {product.relatedProducts.length > 0 &&
                  product.relatedProducts.map((product, i) => (
                    <React.Fragment key={i}>
                      <Link to={`/products/${product.id}`}>{product.name}</Link>
                      <Divider />
                    </React.Fragment>
                  ))}
              </Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>Visibility</Table.Cell>
              <Table.Cell>{product.visibility || 'None'}</Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>Is Upsell</Table.Cell>
              <Table.Cell>{product.isUpsell ? 'Yes' : 'No'}</Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>Is Featured</Table.Cell>
              <Table.Cell>{product.isFeatured ? 'Yes' : 'No'}</Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>Source</Table.Cell>
              <Table.Cell>{product.source || 'housebar'}</Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>Created At</Table.Cell>
              <Table.Cell>{formatDateTime(product.createdAt)}</Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>Updated At</Table.Cell>
              <Table.Cell>{formatDateTime(product.updatedAt)}</Table.Cell>
            </Table.Row>
          </Table.Body>
        </Table>
      </React.Fragment>
    );
  }
}
