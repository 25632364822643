import React from 'react';
import { Link } from 'react-router-dom';
import { Menu, Button } from 'semantic';
import { NavLink } from 'react-router-dom';
import { Breadcrumbs, Layout } from 'components';

import EditLocation from 'modals/EditLocation';

export default ({ location, onSave }) => {
  return (
    <React.Fragment>
      <Breadcrumbs
        link={<Link to="/locations">Locations</Link>}
        active={location.name}></Breadcrumbs>
      <Layout horizontal center spread>
        <h1>{location.name}</h1>
        <Layout.Group>
          <EditLocation
            location={location}
            onSave={onSave}
            trigger={<Button primary icon="setting" content="Settings" />}
          />
        </Layout.Group>
      </Layout>
      <Menu pointing secondary>
        <Menu.Item
          name="Overview"
          to={`/locations/${location.id}`}
          as={NavLink}
          exact
        />

      </Menu>
    </React.Fragment>
  );
};
