import React from 'react';
import { Link } from 'react-router-dom';
import { Menu, Button } from 'semantic';
import { NavLink } from 'react-router-dom';
import { Breadcrumbs, Layout } from 'components';

import EditSurveryQuestion from 'modals/EditSurveryQuestion';

export default ({ question, onSave }) => {
  return (
    <React.Fragment>
      <Breadcrumbs
        link={<Link to="/survey-questions">Questions</Link>}
        active={question.questionText} />
      <Layout horizontal center spread>
        <h1>{question.name}</h1>
        <Layout.Group>
          <EditSurveryQuestion
            question={question}
            onSave={onSave}
            trigger={<Button primary icon="setting" content="Settings" />}
          />
        </Layout.Group>
      </Layout>
      <Menu pointing secondary>
        <Menu.Item
          name="Overview"
          to={`/survey-questions/${question.id}`}
          as={NavLink}
          exact
        />

      </Menu>
    </React.Fragment>
  );
};
