import React from 'react';
import { Table, Button, Message, Confirm } from 'semantic';
import { formatDateTime } from 'utils/date';
import { request } from 'utils/api';
import screen from 'helpers/screen';
import { HelpTip, Breadcrumbs, SearchProvider, Layout } from 'components';
import { urlForUpload } from 'utils/uploads';
import { Image } from 'semantic';
import { Link } from 'react-router-dom';

import Filters from 'modals/Filters';
import EditLocation from 'modals/EditLocation';
import { withLoadedSession } from 'stores';
import { userHasAccess } from 'utils/permissions';
import { visibilityOptions } from '../../../utils/categories';

@screen
@withLoadedSession
export default class LocationList extends React.Component {
  onDataNeeded = async (params) => {
    return await request({
      method: 'POST',
      path: '/1/locations/search',
      body: params,
    });
  };

  render() {
    const writeAccess = userHasAccess(this.context.user, {
      endpoint: 'locations',
      permission: 'write',
      scope: 'global',
    });
    return (
      <SearchProvider onDataNeeded={this.onDataNeeded}>
        {({
          items: locations,
          getSorted,
          setSort,
          filters,
          setFilters,
          reload,
        }) => {
          return (
            <React.Fragment>
              <Breadcrumbs active="Locations" />
              <Layout horizontal center spread>
                <h1>Locations</h1>
                <Layout.Group>
                  <Filters onSave={setFilters} filters={filters}>
                    <Filters.Dropdown
                      search
                      name="type"
                      label="Type"
                      options={[
                        {
                          text: 'store',
                          value: 'store',
                        },
                        {
                          text: 'popup',
                          value: 'popup',
                        },
                        {
                          text: 'flagship-store',
                          value: 'flagship-store',
                        },
                      ]}
                    />
                    <Filters.Text name="name" label="Name" />
                    <Filters.Dropdown
                      search
                      name="visibility"
                      label="Visibility"
                      options={visibilityOptions}
                    />
                    <Filters.Checkbox name="isFeatured" label="Is Featured" />
                  </Filters>
                  <EditLocation
                    trigger={
                      <Button
                        primary
                        content="New Location"
                        icon="plus"
                        disabled={!writeAccess}
                      />
                    }
                    onSave={reload}
                  />
                </Layout.Group>
              </Layout>
              {locations.length === 0 ? (
                <Message>No locations created yet</Message>
              ) : (
                <Table celled sortable>
                  <Table.Header>
                    <Table.Row>
                      <Table.HeaderCell
                        sorted={getSorted('name')}
                        onClick={() => setSort('name')}>
                        Name
                      </Table.HeaderCell>
                      <Table.HeaderCell>Images</Table.HeaderCell>
                      <Table.HeaderCell
                        onClick={() => setSort('createdAt')}
                        sorted={getSorted('createdAt')}>
                        Created
                        <HelpTip
                          title="Created"
                          text="This is the date and time the location was created."
                        />
                      </Table.HeaderCell>
                      <Table.HeaderCell textAlign="center">
                        Actions
                      </Table.HeaderCell>
                    </Table.Row>
                  </Table.Header>
                  <Table.Body>
                    {locations.map((location) => {
                      return (
                        <Table.Row key={location.id}>
                          <Table.Cell>
                            <Link to={`/locations/${location.id}`}>
                              {location.name}
                            </Link>
                          </Table.Cell>
                          <Table.Cell>
                            {location.images && location.images[0] && (
                              <Image
                                size="tiny"
                                src={urlForUpload(location.images[0], true)}
                              />
                            )}
                          </Table.Cell>
                          <Table.Cell>
                            {formatDateTime(location.createdAt)}
                          </Table.Cell>
                          <Table.Cell textAlign="center" singleLine>
                            <EditLocation
                              location={location}
                              trigger={
                                <Button
                                  basic
                                  icon="edit"
                                  disabled={!writeAccess}
                                />
                              }
                              onSave={reload}
                            />
                            <Confirm
                              negative
                              confirmButton="Delete"
                              header={`Are you sure you want to delete "${location.name}"?`}
                              content="All data will be permanently deleted"
                              trigger={
                                <Button
                                  basic
                                  icon="trash"
                                  disabled={!writeAccess}
                                />
                              }
                              onConfirm={async () => {
                                await request({
                                  method: 'DELETE',
                                  path: `/1/locations/${location.id}`,
                                });
                                reload();
                              }}
                            />
                          </Table.Cell>
                        </Table.Row>
                      );
                    })}
                  </Table.Body>
                </Table>
              )}
            </React.Fragment>
          );
        }}
      </SearchProvider>
    );
  }
}
