import React from 'react';
import { Modal, Form, Button, Message } from 'semantic';
import { request } from 'utils/api';
import AutoFocus from 'components/AutoFocus';
import modal from 'helpers/modal';

import Address from 'components/form-fields/Address';
import { visibilityOptions } from '../utils/categories';
import { set } from 'lodash';

@modal
export default class EditLocation extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      loading: false,
      location: props.location || {},
    };
  }

  isUpdate() {
    return !!this.props.location;
  }

  setField = (evt, { name, value }) => {
    const location = { ...this.state.location };
    set(location, name, value);
    this.setState({
      location,
    });
  };

  setNumberField = (evt, { name, value }) => {
    this.setField(evt, { name, value: Number(value) });
  };

  setCheckedField = (evt, { name, checked }) => {
    this.setField(evt, { name, value: checked });
  };

  onSubmit = async () => {
    this.setState({
      loading: true,
    });
    const { location } = this.state;

    try {
      if (this.isUpdate()) {
        await request({
          method: 'PATCH',
          path: `/1/locations/${location.id}`,
          body: location,
        });
      } else {
        await request({
          method: 'POST',
          path: '/1/locations',
          body: {
            ...location,
          },
        });
      }
      this.props.close();
      this.props.onSave();
    } catch (error) {
      this.setState({
        error,
        loading: false,
      });
    }
  };

  render() {
    const { location, loading, error } = this.state;
    return (
      <React.Fragment>
        <Modal.Header>
          {this.isUpdate() ? `Edit "${location.name}"` : 'New Location'}
        </Modal.Header>
        <Modal.Content>
          <AutoFocus>
            <Form
              noValidate
              id="edit-location"
              error={!!error}
              onSubmit={this.onSubmit}>
              {error && <Message error content={error.message} />}
              <Form.Dropdown
                required
                selection
                name="type"
                label="Type"
                value={location.type || ''}
                options={[
                  {
                    text: 'Store',
                    value: 'store',
                  },
                  {
                    text: 'Popup',
                    value: 'popup',
                  },
                  {
                    text: 'Flagship Store',
                    value: 'flagship-store',
                  },
                  {
                    text: 'Warehouse',
                    value: 'warehouse',
                  },
                ]}
                onChange={this.setField}
              />
              <Form.Input
                required
                type="text"
                name="name"
                label="Name"
                value={location.name || ''}
                onChange={this.setField}
              />
              <Form.Input
                required
                type="text"
                name="squareLocationId"
                label="Square Location ID"
                value={location.squareLocationId || ''}
                onChange={this.setField}
              />
              <Form.Group widths="equal">
                <Form.Input
                  required
                  type="time"
                  name="hours.open"
                  label="Open Time"
                  value={location.hours?.open || ''}
                  onChange={this.setField}
                />
                <Form.Input
                  required
                  type="time"
                  name="hours.close"
                  label="Close Time"
                  value={location.hours?.close || ''}
                  onChange={this.setField}
                />
              </Form.Group>
              <Address
                name="address"
                label="Address"
                value={location.address}
                onChange={this.setField}
              />
              <Form.Dropdown
                selection
                required
                name="visibility"
                label="Visibility"
                value={location.visibility || ''}
                options={visibilityOptions}
                onChange={this.setField}
              />
            </Form>
          </AutoFocus>
        </Modal.Content>
        <Modal.Actions>
          <Button
            primary
            form="edit-location"
            loading={loading}
            disabled={loading}
            content={this.isUpdate() ? 'Update' : 'Create'}
          />
        </Modal.Actions>
      </React.Fragment>
    );
  }
}
