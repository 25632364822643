import React from 'react';
import { Table, Button, Message, Confirm } from 'semantic';
import { formatDateTime } from 'utils/date';
import { request } from 'utils/api';
import screen from 'helpers/screen';
import { HelpTip, Breadcrumbs, SearchProvider, Layout } from 'components';
import { urlForUpload } from 'utils/uploads';
import { Image } from 'semantic';
import { Link } from 'react-router-dom';

import Filters from 'modals/Filters';
import EditCocktail from 'modals/EditCocktail';
import {
  cocktailCategoryOptions,
  formatOption,
  renderCocktailFlags,
  themeOptions,
  visibilityOptions,
} from 'utils/categories';
import { withLoadedSession } from 'stores';
import { userHasAccess } from 'utils/permissions';

@screen
@withLoadedSession
export default class CocktailList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      products: [],
      cocktails: [],
    };
  }

  async componentDidMount() {
    const { data: products } = await request({
      method: 'POST',
      path: `/1/products/search`,
      body: {
        includeUpsell: true,
      },
    });

    const { data: cocktails } = await request({
      method: 'POST',
      path: `/1/cocktails/search`,
    });

    this.setState({ products, cocktails });
  }

  onDataNeeded = async (params) => {
    return await request({
      method: 'POST',
      path: '/1/cocktails/search',
      body: params,
    });
  };

  render() {
    const { products } = this.state;

    const writeAccess = userHasAccess(this.context.user, {
      endpoint: 'cocktails',
      permission: 'write',
      scope: 'global',
    });

    return (
      <SearchProvider onDataNeeded={this.onDataNeeded}>
        {({
          items: cocktails,
          getSorted,
          setSort,
          filters,
          setFilters,
          reload,
        }) => {
          return (
            <React.Fragment>
              <Breadcrumbs active="Cocktails" />
              <Layout horizontal center spread>
                <h1>Cocktails</h1>
                <Layout.Group>
                  <Filters onSave={setFilters} filters={filters}>
                    <Filters.Text name="name" label="Name" />
                    <Filters.Dropdown
                      search
                      name="theme"
                      label="Theme"
                      options={themeOptions}
                    />
                    <Filters.Dropdown
                      search
                      multiple
                      allowAdditions
                      name="spirits"
                      label="Spirits"
                    />
                    <Filters.Dropdown
                      search
                      name="visibility"
                      label="Visibility"
                      options={visibilityOptions}
                    />
                    <Filters.Checkbox name="isFeatured" label="Is Featured" />
                    <Filters.Dropdown
                      search
                      multiple
                      allowAdditions
                      name="fragrance"
                      label="Fragrance"
                    />
                    <Filters.Dropdown
                      search
                      multiple
                      allowAdditions
                      name="flavor"
                      label="Flavor"
                    />
                    <Filters.Dropdown
                      search
                      multiple
                      allowAdditions
                      name="intensity"
                      label="Intensity"
                    />
                    <Filters.Number
                      name="recipeDurationMinutes"
                      label="Recipe Duration Minutes"
                    />
                  </Filters>
                  <EditCocktail
                    products={products}
                    trigger={
                      <Button
                        primary
                        content="New Cocktail"
                        icon="plus"
                        disabled={!writeAccess}
                      />
                    }
                    onSave={reload}
                  />
                </Layout.Group>
              </Layout>
              {cocktails.length === 0 ? (
                <Message>No cocktails created yet</Message>
              ) : (
                <Table celled sortable>
                  <Table.Header>
                    <Table.Row>
                      <Table.HeaderCell
                        sorted={getSorted('name')}
                        onClick={() => setSort('name')}>
                        Name
                      </Table.HeaderCell>
                      <Table.HeaderCell width={2}>Category</Table.HeaderCell>
                      <Table.HeaderCell width={2}>Images</Table.HeaderCell>
                      <Table.HeaderCell
                        onClick={() => setSort('createdAt')}
                        sorted={getSorted('createdAt')}
                        width={3}>
                        Created
                        <HelpTip
                          title="Created"
                          text="This is the date and time the cocktail was created."
                        />
                      </Table.HeaderCell>
                      <Table.HeaderCell textAlign="center" width={3}>
                        Actions
                      </Table.HeaderCell>
                    </Table.Row>
                  </Table.Header>
                  <Table.Body>
                    {cocktails.map((cocktail) => {
                      return (
                        <Table.Row key={cocktail.id}>
                          <Table.Cell>
                            <div style={{ float: 'right', marginTop: '-5px' }}>
                              {renderCocktailFlags(cocktail)}
                            </div>
                            <Link to={`/cocktails/${cocktail.id}`}>
                              {cocktail.name}
                            </Link>
                          </Table.Cell>
                          <Table.Cell>
                            {formatOption(
                              cocktailCategoryOptions,
                              cocktail.category
                            )}
                          </Table.Cell>
                          <Table.Cell>
                            {cocktail.images[0] && (
                              <Image
                                size="tiny"
                                src={urlForUpload(cocktail.images[0], true)}
                              />
                            )}
                          </Table.Cell>
                          <Table.Cell>
                            {formatDateTime(cocktail.createdAt)}
                          </Table.Cell>
                          <Table.Cell textAlign="center" singleLine>
                            <Confirm
                              confirmButton="Set Cocktail of the Week"
                              header={`Do you want to feature "${cocktail.name}"?`}
                              content="This cocktail will be featured as the Cocktail of the Week"
                              trigger={
                                <Button
                                  basic
                                  icon="star"
                                  disabled={
                                    !writeAccess || cocktail.isFeaturedForWeek
                                  }
                                />
                              }
                              onConfirm={async () => {
                                try {
                                  await request({
                                    method: 'POST',
                                    path: `/1/cocktails/${cocktail.id}/set-featured-for-week`,
                                  });
                                  reload();
                                } catch (error) {
                                  alert(`Error: ${error.message}`);
                                }
                              }}
                            />
                            <EditCocktail
                              cocktail={cocktail}
                              products={products}
                              trigger={
                                <Button
                                  basic
                                  icon="edit"
                                  disabled={!writeAccess}
                                />
                              }
                              onSave={reload}
                            />
                            <Confirm
                              negative
                              confirmButton="Delete"
                              header={`Are you sure you want to delete "${cocktail.name}"?`}
                              content="All data will be permanently deleted"
                              trigger={
                                <Button
                                  basic
                                  icon="trash"
                                  disabled={!writeAccess}
                                />
                              }
                              onConfirm={async () => {
                                try {
                                  await request({
                                    method: 'DELETE',
                                    path: `/1/cocktails/${cocktail.id}`,
                                  });
                                  reload();
                                  reload();
                                } catch (error) {
                                  alert(error.message);
                                }
                              }}
                            />
                          </Table.Cell>
                        </Table.Row>
                      );
                    })}
                  </Table.Body>
                </Table>
              )}
            </React.Fragment>
          );
        }}
      </SearchProvider>
    );
  }
}
