import React from 'react';
import { Table, Button, Message, Confirm } from 'semantic';
import screen from 'helpers/screen';
import Menu from './Menu';
import { request } from 'utils/api';

import { formatDateTime } from 'utils/date';

import { HelpTip, Breadcrumbs, SearchProvider, Layout } from 'components';
import { Link } from 'react-router-dom';

@screen
export default class ProductInventories extends React.Component {
  onDataNeeded = async (params) => {
    const { product } = this.props;
    return await request({
      method: 'POST',
      path: '/1/inventories/search',
      body: {
        ...params,
        product: product.id,
      },
    });
  };

  render() {
    const { product } = this.props;
    return (
      <React.Fragment>
        <Menu {...this.props} />
        <SearchProvider onDataNeeded={this.onDataNeeded}>
          {({
            items: inventories,
            getSorted,
            setSort,
            filters,
            setFilters,
            reload,
          }) => {
            return (
              <React.Fragment>
                <Layout horizontal center spread>
                  <h1>Inventory</h1>
                </Layout>
                {inventories.length === 0 ? (
                  <Message>No inventory entries yet</Message>
                ) : (
                  <Table celled sortable>
                    <Table.Header>
                      <Table.Row>
                        <Table.HeaderCell>Product Variant</Table.HeaderCell>
                        <Table.HeaderCell>Location</Table.HeaderCell>
                        <Table.HeaderCell
                          onClick={() => setSort('quantity')}
                          sorted={getSorted('quantity')}>
                          Quantity
                        </Table.HeaderCell>
                        <Table.HeaderCell
                          onClick={() => setSort('updatedAt')}
                          sorted={getSorted('updatedAt')}>
                          Last Updated
                        </Table.HeaderCell>
                      </Table.Row>
                    </Table.Header>
                    <Table.Body>
                      {inventories.map((inventory) => {
                        return (
                          <Table.Row key={inventory.id}>
                            <Table.Cell>
                              {inventory.productVariant.name}
                              <p>
                                <small>{inventory.productVariant.sku}</small>
                              </p>
                            </Table.Cell>
                            <Table.Cell>
                              <Link to={`/locations/${inventory.location.id}`}>
                                {inventory.location.name}
                              </Link>
                            </Table.Cell>
                            <Table.Cell>{inventory.quantity}</Table.Cell>
                            <Table.Cell>
                              {formatDateTime(inventory.updatedAt)}
                            </Table.Cell>
                          </Table.Row>
                        );
                      })}
                    </Table.Body>
                  </Table>
                )}
              </React.Fragment>
            );
          }}
        </SearchProvider>
      </React.Fragment>
    );
  }
}
