import React from 'react';
import { Table, Divider, Image } from 'semantic';
import screen from 'helpers/screen';
import Menu from './Menu';

import { formatDateTime } from 'utils/date';
import { urlForUpload } from 'utils/uploads';

@screen
export default class LocationOverview extends React.Component {
  render() {
    const location = this.props.locationObject;
    return (
      <React.Fragment>
        <Menu location={location} onSave={this.props.onSave} />
        <Divider hidden />
        <Image.Group size="small">
          {(location.images || []).map((image) => (
            <Image key={image.id} src={urlForUpload(image)} />
          ))}
        </Image.Group>
        <Table definition>
          <Table.Body>
            <Table.Row>
              <Table.Cell>Type</Table.Cell>
              <Table.Cell>{location.type || 'None'}</Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>Description</Table.Cell>
              <Table.Cell>{location.description || 'None'}</Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>Visibility</Table.Cell>
              <Table.Cell>{location.visibility || 'None'}</Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>Is Featured</Table.Cell>
              <Table.Cell>{location.isFeatured ? 'Yes' : 'No'}</Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>Square Location Id</Table.Cell>
              <Table.Cell>{location.squareLocationId || 'None'}</Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>Created At</Table.Cell>
              <Table.Cell>{formatDateTime(location.createdAt)}</Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>Updated At</Table.Cell>
              <Table.Cell>{formatDateTime(location.updatedAt)}</Table.Cell>
            </Table.Row>
          </Table.Body>
        </Table>
      </React.Fragment>
    );
  }
}
