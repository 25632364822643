import React from 'react';
import { Modal, Form, Button, Message } from 'semantic';
import { request } from 'utils/api';
import AutoFocus from 'components/AutoFocus';
import modal from 'helpers/modal';

import UploadsField from 'components/form-fields/Uploads';
import RichTextField from '../components/form-fields/RichText';
import ReferenceField from 'components/form-fields/Reference';
import {
  categoryOptions,
  spiritsByType,
  productTypeOptions,
  visibilityOptions,
} from 'utils/categories';

@modal
export default class EditProduct extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      loading: false,
      product: props.product || {},
    };
  }

  isUpdate() {
    return !!this.props.product;
  }

  setField = (evt, { name, value }) => {
    this.setState({
      product: {
        ...this.state.product,
        [name]: value,
      },
    });
  };

  setNumberField = (evt, { name, value }) => {
    this.setField(evt, { name, value: Number(value) });
  };

  setCheckedField = (evt, { name, checked }) => {
    this.setField(evt, { name, value: checked });
  };

  /**
   * Checks product to see if it has a variant, if not, override visibility to draft
   */
  patchVariant = () => {
    const { product } = this.state;
    if (product.variants?.length === 0) {
      product.visibility = 'draft';
    }
  };

  /**
   * Validates that there is at least one image uploaded if the product is set to published
   */
  validateImages = () => {
    const { product } = this.state;

    if (product.visibility === 'published') {
      if (!product.name || !product.name.length) {
        throw new Error('In order to publish a product, you must add a name');
      }
      if (!product.type) {
        throw new Error('In order to publish a product, you must add a type');
      }
      if (!product.brandName || !product.brandName.length) {
        throw new Error(
          'In order to publish a product, you must add a brandName'
        );
      }
      if (!product.description || !product.description.length) {
        throw new Error(
          'In order to publish a product, you must add a description'
        );
      }
      if (product.images?.length < 1) {
        throw new Error(
          'In order to publish a product, you must upload at least 1 image'
        );
      }
    }
  };

  onSubmit = async () => {
    this.setState({
      loading: true,
    });
    const { product } = this.state;

    try {
      this.patchVariant();
      this.validateImages();

      if (this.isUpdate()) {
        await request({
          method: 'PATCH',
          path: `/1/products/${product.id}`,
          body: product,
        });
      } else {
        await request({
          method: 'POST',
          path: '/1/products',
          body: {
            ...product,
          },
        });
      }
      this.props.close();
      this.props.onSave();
    } catch (error) {
      this.setState({
        error,
        loading: false,
      });
    }
  };

  spiritLabel = () => {
    try {
      return productTypeOptions
        .filter((t) => t.value === this.state.product.type)
        .pop().text;
    } catch (e) {
      return '';
    }
  };

  render() {
    const { product, loading, error } = this.state;
    return (
      <React.Fragment>
        <Modal.Header>
          {this.isUpdate() ? `Edit "${product.name}"` : 'New Product'}
        </Modal.Header>
        <Modal.Content>
          <AutoFocus>
            <Form
              noValidate
              id="edit-product"
              error={!!error}
              onSubmit={this.onSubmit}>
              {error && <Message error content={error.message} />}
              <Form.Dropdown
                selection
                required
                name="type"
                label="Type"
                value={product.type || ''}
                options={productTypeOptions}
                onChange={this.setField}
              />
              {product.type && (
                <Form.Dropdown
                  required
                  selection
                  name="spirit"
                  label={`${this.spiritLabel()} Type`}
                  value={product.spirit || ''}
                  options={spiritsByType(product.type)}
                  onChange={this.setField}
                />
              )}
              <Form.Dropdown
                selection
                multiple
                name="categories"
                label="Special Tag"
                value={product.categories || []}
                options={categoryOptions}
                onChange={this.setField}
              />
              <Form.Dropdown
                selection
                search
                openOnFocus={false}
                clearable
                name="subType"
                label="Sub Type"
                value={product.subType}
                options={window.meta.subTypes}
                onChange={this.setField}
              />
              <Form.Input
                required
                type="text"
                name="brandName"
                label="Brand Name"
                value={product.brandName || ''}
                onChange={this.setField}
              />
              <Form.Input
                required
                type="text"
                name="name"
                label="Product Name"
                value={product.name || ''}
                onChange={this.setField}
              />
              <UploadsField
                required
                name="images"
                label="Product Image(s)"
                value={product.images || []}
                onChange={this.setField}
                onError={(error) => this.setState({ error })}
              />
              <RichTextField
                required
                type="text"
                name="description"
                label="Product Description"
                value={product.description || ''}
                onChange={this.setField}
                short={true}
              />
              <Form.Input
                type="text"
                name="whyWeDigIt"
                label="Why We Dig It"
                value={product.whyWeDigIt || ''}
                onChange={this.setField}
              />
              <ReferenceField
                name="relatedProducts"
                label="Related Products"
                value={product.relatedProducts || []}
                path="/1/products/search"
                placeholder="Search Products"
                onChange={this.setField}
              />
              <Form.Dropdown
                required
                selection
                name="visibility"
                label="Visibility"
                value={product.visibility || ''}
                options={visibilityOptions}
                onChange={this.setField}
              />
              <Form.Input
                type="text"
                name="squareReference"
                label="Square ID"
                value={product.squareReference || ''}
                onChange={this.setField}
              />
              <Form.Group>
                <Form.Checkbox
                  name="isUpsell"
                  label="Upsell this product"
                  checked={product.isUpsell}
                  onChange={this.setCheckedField}
                />
                <Form.Checkbox
                  name="isFeatured"
                  label="Is Featured"
                  checked={product.isFeatured}
                  onChange={this.setCheckedField}
                />
                <Form.Checkbox
                  name="isSpecialAndRare"
                  label="Is Special and Rare (Enables market place)"
                  checked={product.isSpecialAndRare}
                  onChange={this.setCheckedField}
                />
                <Form.Checkbox
                  name="isDisabled"
                  label="Is Disabled for Online Sales"
                  checked={product.isDisabled}
                  onChange={this.setCheckedField}
                />
              </Form.Group>
            </Form>
          </AutoFocus>
        </Modal.Content>
        <Modal.Actions>
          <Button
            primary
            form="edit-product"
            loading={loading}
            disabled={loading}
            content={this.isUpdate() ? 'Update' : 'Create'}
          />
        </Modal.Actions>
      </React.Fragment>
    );
  }
}
