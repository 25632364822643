import screen from '../../../helpers/screen';
import React from 'react';
import Menu from '../Detail/Menu';
import { Divider, Modal, Icon, Button, Accordion } from 'semantic';
import { request } from '../../../utils/api';
import EditForm from './EditForm';

@screen
export default class ProductVariants extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      addMode: false,
      product: props.product || {},
      variants: props.product.variants || [],
      newVariant: {},
      selectedVariant: -1,
    };
  }

  async componentDidMount() {
    await this.refreshProduct();
  }

  toggleSelectedVariant = (index) => {
    const { selectedVariant } = this.state;
    this.setState({ selectedVariant: selectedVariant === index ? -1 : index });
  };

  onUpdate = async () => {
    this.setState({ selectedVariant: -1, addMode: false });
    await this.refreshProduct();
  };

  refreshProduct = async () => {
    const { product } = this.state;
    const { data } = await request({
      method: 'GET',
      path: `/1/products/${product.id}`,
    });
    this.setState({ product: data, variants: data.variants });
  };

  renderVariants = () => {
    const { variants, selectedVariant, product } = this.state;
    return variants.map((variant, i) => (
      <Accordion key={variant.id} styled fluid>
        <Accordion.Title
          active={i === selectedVariant}
          index={i}
          onClick={() => this.toggleSelectedVariant(i)}>
          <Icon name="arrow-right" className="dropdown" />
          {variant.name}
        </Accordion.Title>
        <Accordion.Content active={i === selectedVariant}>
          <EditForm
            variant={variant}
            addMode={false}
            product={product}
            onUpdate={this.onUpdate}
            onDelete={this.refreshProduct}
          />
        </Accordion.Content>
      </Accordion>
    ));
  };

  renderAddModal = () => {
    const { variants, newVariant, addMode, product } = this.state;
    const newVariants = [...variants, newVariant];

    return (
      <Modal
        open={addMode}
        onOpen={() => {
          this.setState({
            addMode: true,
            variants: newVariants,
            selectedVariant: newVariants.length - 1,
          });
        }}
        onClose={() => {
          variants.pop();
          this.setState({ selectedVariant: -1, variants, addMode: false });
        }}
        trigger={<Button primary>Add new variant</Button>}>
        <Modal.Content>
          <EditForm
            variant={newVariant}
            addMode={true}
            product={product}
            onUpdate={this.onUpdate}
            onDelete={this.refreshProduct}
          />
        </Modal.Content>
      </Modal>
    );
  };

  async resync() {
    const { product } = this.state;
    const proceed = confirm(
      'This will delete all product variants for this product and re-populate them from Square. Are you sure?'
    );
    if (!proceed) {
      return;
    }
    try {
      this.setState({ resyncLoading: true });
      await request({
        method: 'POST',
        path: `/1/products/${product.id}/resync-variants`,
      });
      this.setState({ resyncLoading: false });
      this.refreshProduct();
    } catch (error) {
      this.setState({ resyncLoading: false });
      alert(`Could not resync: ${error.message}`);
    }
  }

  render() {
    return (
      <React.Fragment>
        <Menu {...this.props} />
        <Divider hidden />
        {false && this.renderAddModal()}
        <Divider hidden />
        {this.renderVariants()}
        <Divider hidden />
        <div>
          <Button
            basic
            loading={this.state.resyncLoading}
            color="red"
            content="Clear and resync variants"
            icon="undo"
            onClick={() => this.resync()}
          />
        </div>
      </React.Fragment>
    );
  }
}
