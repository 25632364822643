import React from 'react';
import { Table, Divider, Image } from 'semantic';
import screen from 'helpers/screen';
import Menu from './Menu';

import { formatDateTime } from 'utils/date';
import { urlForUpload } from 'utils/uploads';

@screen
export default class PostOverview extends React.Component {
  render() {
    const { post } = this.props;
    return (
      <React.Fragment>
        <Menu {...this.props} />
        <Divider hidden />
        <Image.Group size="small">
          {post.images.map((image) => (
            <Image key={image.id} src={urlForUpload(image)} />
          ))}
        </Image.Group>
        <Table definition>
          <Table.Body>
            <Table.Row>
              <Table.Cell>Title</Table.Cell>
              <Table.Cell>{post.title || 'None'}</Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>Type</Table.Cell>
              <Table.Cell>{post.type || 'None'}</Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>Description</Table.Cell>
              <Table.Cell>{post.description || 'None'}</Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>Introduction Text</Table.Cell>
              <Table.Cell>{post.introductionText || 'None'}</Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>Body Text</Table.Cell>
              <Table.Cell>{post.bodyText || 'None'}</Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>Drink Experience Type</Table.Cell>
              <Table.Cell>{post.drinkExperienceType || 'None'}</Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>Spirits</Table.Cell>
              <Table.Cell>{post.spirits.join(', ') || 'None'}</Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>Videos Title</Table.Cell>
              <Table.Cell>{post.videosTitle || 'None'}</Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>Video Playlist Title</Table.Cell>
              <Table.Cell>{post.videoPlaylistTitle || 'None'}</Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>Products Title</Table.Cell>
              <Table.Cell>{post.productsTitle || 'None'}</Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>Related Posts Title</Table.Cell>
              <Table.Cell>{post.relatedPostsTitle || 'None'}</Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>Quote</Table.Cell>
              <Table.Cell>{post.quote || 'None'}</Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>Visibility</Table.Cell>
              <Table.Cell>{post.visibility || 'None'}</Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>Is Featured</Table.Cell>
              <Table.Cell>{post.isFeatured ? 'Yes' : 'No'}</Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>Created At</Table.Cell>
              <Table.Cell>{formatDateTime(post.createdAt)}</Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>Updated At</Table.Cell>
              <Table.Cell>{formatDateTime(post.updatedAt)}</Table.Cell>
            </Table.Row>
          </Table.Body>
        </Table>
      </React.Fragment>
    );
  }
}
