import React from 'react';
import { Table } from 'semantic';
import { Link } from 'react-router-dom';
import { formatCurrency } from 'utils/currency';

export default class OrderItems extends React.Component {
  render() {
    const { order } = this.props;
    return (
      <React.Fragment>
        <Table collapsing>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>Product</Table.HeaderCell>
              <Table.HeaderCell>Quantity</Table.HeaderCell>
              <Table.HeaderCell>Unit Price</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {order.items.map((item) => {
              return (
                <Table.Row key={item.id}>
                  <Table.Cell>
                    <Link to={`/products/${item.product?.id}`}>
                      {item.product?.name} {item.product?.brandName || ''}
                    </Link>
                    &nbsp;
                    <Link to={`/products/${item.product?.id}`}>
                      {item.productVariant?.name}
                    </Link>
                  </Table.Cell>
                  <Table.Cell>{item.quantity}</Table.Cell>
                  <Table.Cell>
                    {formatCurrency(item.unitPrice / 100, item.currency)}
                  </Table.Cell>
                </Table.Row>
              );
            })}
          </Table.Body>
          <Table.Footer>
            <Table.Row>
              <Table.HeaderCell colspan={2} textAlign="right">
                Total Pre-Tax Amount:
              </Table.HeaderCell>
              <Table.HeaderCell>
                {formatCurrency(order.totalPreTaxAmount / 100, order.currency)}
              </Table.HeaderCell>
            </Table.Row>
            <Table.Row>
              <Table.HeaderCell colspan={2} textAlign="right">
                Tax:
              </Table.HeaderCell>
              <Table.HeaderCell>
                {order.taxRate * 100}% (
                {formatCurrency(order.totalTaxAmount / 100, order.currency)})
              </Table.HeaderCell>
            </Table.Row>
            <Table.Row>
              <Table.HeaderCell colspan={2} textAlign="right">
                <strong>Grand Total:</strong>
              </Table.HeaderCell>
              <Table.HeaderCell>
                <strong>
                  {formatCurrency(order.totalAmount / 100, order.currency)}
                </strong>
              </Table.HeaderCell>
            </Table.Row>
          </Table.Footer>
        </Table>
      </React.Fragment>
    );
  }
}
