import React from 'react';
import { Link } from 'react-router-dom';
import { Switch, Route } from 'react-router-dom';
import { Protected } from 'helpers/routes';
import { Loader } from 'semantic';
import { request } from 'utils/api';

import NotFound from 'screens/NotFound';
import Overview from './Overview';

export default class CuratedListDetail extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      curatedList: null,
      products: [],
      cocktails: [],
      error: null,
      loading: true,
    };
  }

  onSave = () => {
    this.fetchCuratedList();
  };

  componentDidMount() {
    this.fetchCuratedList();
  }

  componentDidUpdate(lastProps) {
    const { id } = this.props.match.params;
    if (id !== lastProps.match.params.id) {
      this.fetchCuratedList();
    }
  }

  async fetchCuratedList() {
    const { id } = this.props.match.params;
    try {
      this.setState({
        error: null,
        loading: true,
      });
      const { data: curatedList } = await request({
        method: 'GET',
        path: `/1/curated-lists/${id}`,
      });
      const { data: products } = await request({
        method: 'POST',
        path: `/1/products/search`,
        body: {
          includeUpsell: true
        }
      });
      const { data: cocktails } = await request({
        method: 'POST',
        path: `/1/cocktails/search`
      });
      this.setState({
        curatedList,
        products,
        cocktails,
        loading: false,
      });
    } catch (error) {
      this.setState({
        error,
        loading: false,
      });
    }
  }

  render() {
    const { loading, error } = this.state;
    if (loading) {
      return <Loader active>Loading</Loader>;
    } else if (error) {
      return (
        <NotFound
          link={<Link to="/curated-lists">Curated Lists</Link>}
          message="Sorry that list wasn't found."
        />
      );
    }

    const props = {
      ...this.state,
      onSave: this.onSave,
    };
    return (
      <Switch>
        <Protected exact path="/curated-lists/:id" allowed={Overview} {...props} />

        <Route component={NotFound} />
      </Switch>
    );
  }
}
