import React from 'react';
import { set } from 'lodash';
import { Modal, Form, Button, Message } from 'semantic';
import { request } from 'utils/api';
import AutoFocus from 'components/AutoFocus';
import modal from 'helpers/modal';

import ArrayField from "../components/form-fields/Array";
import {themeOptions} from "../utils/categories";

@modal
export default class EditSurveryQuestion extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      loading: false,
      question: props.question || {},
    };
  }

  isUpdate() {
    return !!this.props.question;
  }

  setField = (evt, { name, value }) => {
    const question = { ...this.state.question };
    set(question, name, value);
    this.setState({ question });
  };

  onSubmit = async () => {
    this.setState({ loading: true });
    const { question } = this.state;

    try {
      if (this.isUpdate()) {
        await request({
          method: 'PATCH',
          path: `/1/survey-questions/${question.id}`,
          body: question,
        });
      } else {
        await request({
          method: 'POST',
          path: '/1/survey-questions',
          body: {
            ...question,
          },
        });
      }
      this.props.close();
      this.props.onSave();
    } catch (error) {
      this.setState({
        error,
        loading: false,
      });
    }
  };

  render() {
    const { question, loading, error } = this.state;
    return (
      <React.Fragment>
        <Modal.Header>
          {this.isUpdate() ? `Edit "${question.questionText}"` : 'New Question'}
        </Modal.Header>
        <Modal.Content>
          <AutoFocus>
            <Form
              noValidate
              id="edit-survey-question"
              error={!!error}
              onSubmit={this.onSubmit}>
              {error && <Message error content={error.message} />}
              <Form.Group widths="equal">
                <Form.Dropdown
                  required
                  selection
                  name="type"
                  label="Type"
                  value={question.type || ''}
                  options={[
                    {
                      text: "Multiple Choice",
                      value: "multiple-choice",
                    }
                  ]}
                  onChange={this.setField}
                />
                <Form.Dropdown
                  selection
                  name="theme"
                  label="Theme"
                  value={question.theme || ''}
                  options={themeOptions}
                  onChange={this.setField}
                />
              </Form.Group>
              <Form.Input
                required
                type="text"
                name="questionText"
                label="Question"
                value={question.questionText || ''}
                onChange={this.setField}
              />
              <ArrayField
                label="Responses"
                name="responses"
                childLabel="Response"
                onChange={this.setField}
                value={question.responses || []}
                getNewField={() => Object({ responseText: '', subText: '' })}>
                {(question.responses || []).map((response, i) => {
                  const { responseText, subText } = response;
                  return (
                    <React.Fragment key={i}>
                      <Form.Input
                        required
                        label="Question"
                        type="text"
                        name={`responses.${i}.responseText`}
                        value={responseText || ''}
                        onChange={this.setField}
                      />
                      <Form.Input
                        type="text"
                        name={`responses.${i}.subText`}
                        label="Sub Text"
                        value={subText}
                        onChange={this.setField}
                      />
                    </React.Fragment>
                  );
                })}
              </ArrayField>
            </Form>
          </AutoFocus>
        </Modal.Content>
        <Modal.Actions>
          <Button
            primary
            form="edit-survey-question"
            loading={loading}
            disabled={loading}
            content={this.isUpdate() ? 'Update' : 'Create'}
          />
        </Modal.Actions>
      </React.Fragment>
    );
  }
}
