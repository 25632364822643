import React from 'react';
import { Modal, Form, Button, Message } from 'semantic';
import { request } from 'utils/api';
import AutoFocus from 'components/AutoFocus';
import modal from 'helpers/modal';


@modal
export default class EditOrder extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      loading: false,
      order: props.order || {},
    };
  }

  isUpdate() {
    return !!this.props.order;
  }

  setField = (evt, { name, value }) => {
    this.setState({
      order: {
        ...this.state.order,
        [name]: value,
      },
    });
  };

  setNumberField = (evt, { name, value }) => {
    this.setField(evt, { name, value: Number(value) });
  };

  setCheckedField = (evt, { name, checked }) => {
    this.setField(evt, { name, value: checked });
  };

  onSubmit = async () => {
    this.setState({
      loading: true,
    });
    const { order } = this.state;

    try {
      if (this.isUpdate()) {
        await request({
          method: 'PATCH',
          path: `/1/orders/${order.id}`,
          body: order,
        });
      } else {
        await request({
          method: 'POST',
          path: '/1/orders',
          body: {
            user: this.props.user?.id || order.user?.id,
            ...order,
          },
        });
      }
      this.props.close();
      this.props.onSave();
    } catch (error) {
      this.setState({
        error,
        loading: false,
      });
    }
  };

  render() {
    const { order, loading, error } = this.state;
    return (
      <React.Fragment>
        <Modal.Header>
          {this.isUpdate() ? `Edit Order` : 'New Order'}
        </Modal.Header>
        <Modal.Content>
          <AutoFocus>
            <Form
              noValidate
              id="edit-order"
              error={!!error}
              onSubmit={this.onSubmit}>
              {error && <Message error content={error.message} />}
              <Form.Dropdown
                required
                selection
                name="status"
                label="Status"
                value={order.status || ''}
                options={[
                  {
                    text: 'Draft',
                    value: 'draft',
                  },
                  {
                    text: 'Created',
                    value: 'created',
                  },
                  {
                    text: 'Failed',
                    value: 'failed',
                  },
                ]}
                onChange={this.setField}
              />
              <Form.Dropdown
                selection
                name="deliveryType"
                label="Delivery Type"
                value={order.deliveryType || ''}
                options={[
                  {
                    text: 'Pickup',
                    value: 'pickup',
                  },
                  {
                    text: 'Shipping',
                    value: 'shipping',
                  },
                  {
                    text: 'Courrier',
                    value: 'courrier',
                  },
                ]}
                onChange={this.setField}
              />
              {order.deliveryType === 'pickup' && (
                <Form.Dropdown
                  selection
                  name="pickupStatus"
                  label="Pickup Status"
                  value={order.pickupStatus || ''}
                  options={[
                    {
                      text: 'Preparing',
                      value: 'preparing',
                    },
                    {
                      text: 'Ready for Pickup',
                      value: 'ready-for-pickup',
                    },
                    {
                      text: 'Picked Up',
                      value: 'picked-up',
                    },
                  ]}
                  onChange={this.setField}
                />
              )}
              {order.deliveryType === 'courrier' && (
                <Form.Dropdown
                  selection
                  name="deliveryStatus"
                  label="Delivery Status"
                  value={order.deliveryStatus || ''}
                  options={[
                    {
                      text: 'Preparing',
                      value: 'preparing',
                    },
                    {
                      text: 'Out for Delivery',
                      value: 'out-for-delivery',
                    },
                    {
                      text: 'Delivered',
                      value: 'delivered',
                    },
                  ]}
                  onChange={this.setField}
                />
              )}
            </Form>
          </AutoFocus>
        </Modal.Content>
        <Modal.Actions>
          <Button
            primary
            form="edit-order"
            loading={loading}
            disabled={loading}
            content={this.isUpdate() ? 'Update' : 'Create'}
          />
        </Modal.Actions>
      </React.Fragment>
    );
  }
}
