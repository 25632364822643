import React from 'react';
import { Link } from 'react-router-dom';
import { Switch, Route } from 'react-router-dom';
import { Protected } from 'helpers/routes';
import { Loader } from 'semantic';
import { request } from 'utils/api';

import NotFound from 'screens/NotFound';
import Overview from './Overview';

export default class CocktailDetail extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      products: [],
      cocktail: null,
      error: null,
      loading: true,
    };
  }

  onSave = async () => {
    await this.fetchCocktail();
  };

  async componentDidMount() {
    await this.fetchCocktail();
    await this.fetchProducts();
  }

  async componentDidUpdate(lastProps) {
    const { id } = this.props.match.params;
    if (id !== lastProps.match.params.id) {
      await this.fetchCocktail();
    }
  }

  async fetchProducts() {
    try {
      this.setState({ error: null, loading: true });

      const { data: products } = await request({
        method: 'POST',
        path: `/1/products/search`,
        body: {
          includeUpsell: true,
        },
      });
      this.setState({ products, loading: false });
    } catch (error) {
      this.setState({ error, loading: false });
    }
  }

  async fetchCocktail() {
    const { id } = this.props.match.params;
    try {
      this.setState({
        error: null,
        loading: true,
      });
      const { data } = await request({
        method: 'GET',
        path: `/1/cocktails/${id}`,
      });
      this.setState({
        cocktail: data,
        loading: false,
      });
    } catch (error) {
      this.setState({
        error,
        loading: false,
      });
    }
  }

  render() {
    const { loading, error } = this.state;
    if (loading) {
      return <Loader active>Loading</Loader>;
    } else if (error) {
      return (
        <NotFound
          link={<Link to="/cocktails">Cocktails</Link>}
          message="Sorry that cocktail wasn't found."
        />
      );
    }

    const props = {
      ...this.state,
      onSave: this.onSave,
    };
    return (
      <Switch>
        <Protected exact path="/cocktails/:id" allowed={Overview} {...props} />

        <Route component={NotFound} />
      </Switch>
    );
  }
}
