import React from 'react';
import { Link } from 'react-router-dom';
import { Menu, Button } from 'semantic';
import { NavLink } from 'react-router-dom';
import { Breadcrumbs, Layout } from 'components';

import EditCocktail from 'modals/EditCocktail';

export default ({ cocktail, products, onSave }) => {
  return (
    <React.Fragment>
      <Breadcrumbs link={<Link to="/cocktails">Cocktails</Link>} active={cocktail.name} />
      <Layout horizontal center spread>
        <h1>{cocktail.name}</h1>
        <Layout.Group>
          <EditCocktail
            products={products}
            cocktail={cocktail}
            onSave={onSave}
            trigger={<Button primary icon="setting" content="Settings" />}
          />
        </Layout.Group>
      </Layout>
      <Menu pointing secondary>
        <Menu.Item
          name="Overview"
          to={`/cocktails/${cocktail.id}`}
          as={NavLink}
          exact
        />
      </Menu>
    </React.Fragment>
  );
};
