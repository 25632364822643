import React from 'react';
import { Link } from 'react-router-dom';
import { Menu, Button } from 'semantic';
import { NavLink } from 'react-router-dom';
import { Breadcrumbs, Layout } from 'components';

import EditOrder from 'modals/EditOrder';
import UpdateOrderDeliveryStatus from 'modals/UpdateOrderDeliveryStatus';
import UpdateOrderPickupStatus from 'modals/UpdateOrderPickupStatus';

export default ({ order, onSave }) => {
  return (
    <React.Fragment>
      <Breadcrumbs
        link={<Link to="/orders">Orders</Link>}
        active={order.id}></Breadcrumbs>
      <Layout horizontal center spread>
        <h1>Order {order.id}</h1>
        <Layout.Group>
          {order.squareReference && (
            <Button
              basic
              icon="external-link-alt"
              content="View on Square"
              as="a"
              href={`https://squareup.com/dashboard/orders`}
            />
          )}
          {order.deliveryStatus === 'out-for-delivery' && (
            <UpdateOrderDeliveryStatus
              order={order}
              onSave={onSave}
              trigger={
                <Button primary icon="truck" content="Set Delivery Status" />
              }
            />
          )}
          {order.deliveryType === 'pickup' &&
            order.pickupStatus === 'preparing' && (
              <UpdateOrderPickupStatus
                order={order}
                onSave={onSave}
                deliveryStatus={{ eventId: 'ready-for-pickup' }}
                trigger={
                  <Button primary icon="clock" content="Set Pickup Status" />
                }
              />
            )}

          {order.deliveryType === 'pickup' &&
            order.pickupStatus === 'ready-for-pickup' && (
              <UpdateOrderPickupStatus
                order={order}
                onSave={onSave}
                deliveryStatus={{ eventId: 'picked-up' }}
                trigger={
                  <Button primary icon="check" content="Set Pickup Status" />
                }
              />
            )}
          <EditOrder
            order={order}
            onSave={onSave}
            trigger={<Button primary icon="setting" content="Settings" />}
          />
        </Layout.Group>
      </Layout>
      <Menu pointing secondary>
        <Menu.Item
          name="Overview"
          to={`/orders/${order.id}`}
          as={NavLink}
          exact
        />
      </Menu>
    </React.Fragment>
  );
};
