import React from 'react';
import { set } from 'lodash';
import { Modal, Form, Button, Message } from 'semantic';
import { request } from 'utils/api';
import AutoFocus from 'components/AutoFocus';
import modal from 'helpers/modal';

import UploadsField from 'components/form-fields/Uploads';
import ReferenceField from 'components/form-fields/Reference';
import RichTextField from '../components/form-fields/RichText';
import ArrayField from '../components/form-fields/Array';
import { visibilityOptions, postLinkSourceOptions } from '../utils/categories';

@modal
export default class EditPost extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      loading: false,
      post: props.post || {},
      spiritTypes: [],
    };
  }

  async componentDidMount() {
    await this.fetchSpiritTypes();
  }

  /**
   * Fetches /products/spirit-types and sets the returned value to spiritTypes in state
   * @returns {Promise<void>}
   */
  async fetchSpiritTypes() {
    try {
      this.setState({ loading: true });
      const { data: spiritTypes } = await request({ method: 'GET', path: `/1/products/spirit-types` });
      this.setState({ spiritTypes, loading: false });
    } catch (error) {
      this.setState({ error, loading: false });
    }
  }

  isUpdate() {
    return !!this.props.post;
  }

  setField = (evt, { name, value }) => {
    const post = { ...this.state.post };
    set(post, name, value);
    this.setState({ post });
  };

  setNumberField = (evt, { name, value }) => {
    this.setField(evt, { name, value: Number(value) });
  };

  setCheckedField = (evt, { name, checked }) => {
    this.setField(evt, { name, value: checked });
  };

  onSubmit = async () => {
    this.setState({
      loading: true,
    });
    const { post } = this.state;

    try {
      if (this.isUpdate()) {
        await request({
          method: 'PATCH',
          path: `/1/posts/${post.id}`,
          body: post,
        });
      } else {
        await request({
          method: 'POST',
          path: '/1/posts',
          body: {
            ...post,
          },
        });
      }
      this.props.close();
      this.props.onSave();
    } catch (error) {
      this.setState({
        error,
        loading: false,
      });
    }
  };

  render() {
    const { post, spiritTypes, loading, error } = this.state;
    return (
      <React.Fragment>
        <Modal.Header>
          {this.isUpdate() ? `Edit Post` : 'New Post'}
        </Modal.Header>
        <Modal.Content>
          <AutoFocus>
            <Form
              noValidate
              id="edit-post"
              error={!!error}
              onSubmit={this.onSubmit}>
              {error && <Message error content={error.message} />}
              <Form.Dropdown
                required
                selection
                name="type"
                label="Type"
                value={post.type || ''}
                options={[
                  {
                    text: 'video',
                    value: 'video',
                  },
                  {
                    text: 'drink-experience',
                    value: 'drink-experience',
                  },
                  {
                    text: 'story',
                    value: 'story',
                  },
                  {
                    text: 'podcast',
                    value: 'podcast',
                  },
                  {
                    text: 'playlist',
                    value: 'playlist',
                  },
                ]}
                onChange={this.setField}
              />
              <Form.Dropdown
                selection
                name="drinkExperienceType"
                label="Drink Experience Type"
                value={post.drinkExperienceType || ''}
                options={[
                  {
                    text: 'Music',
                    value: 'music',
                  },
                  {
                    text: 'Movies',
                    value: 'movies',
                  },
                  {
                    text: 'Parties',
                    value: 'parties',
                  }
                ]}
                onChange={this.setField}
              />
              <Form.Input
                type="text"
                name="subTitle"
                label="Cover Title"
                value={post.subTitle || ''}
                onChange={this.setField}
              />
              {post.drinkExperienceType === 'music' && (
                <React.Fragment>
                  <Form.Input
                    type="text"
                    name="audioTitle"
                    label="Playlist Title"
                    value={post.audioTitle || ''}
                    onChange={this.setField}
                  />
                  <UploadsField
                    name="audioThumbnailImage"
                    label="Audio Thumbnail Image"
                    value={post.audioThumbnailImage}
                    onChange={this.setField}
                    onError={(error) => this.setState({ error })}
                  />
                </React.Fragment>
              )}
              <Form.Input
                required
                type="text"
                name="title"
                label="Card Title"
                value={post.title || ''}
                onChange={this.setField}
              />
              <Form.Input
                required
                type="text"
                name="postTitle"
                label="Post Title"
                value={post.postTitle || ''}
                onChange={this.setField}
              />
              <UploadsField
                required
                name="images"
                label="Images"
                value={post.images || []}
                onChange={this.setField}
                onError={(error) => this.setState({ error })}
              />
              <ArrayField
                label="Links"
                name="links"
                childLabel="Links"
                onChange={this.setField}
                value={post.links || []}
                getNewField={() => {
                  return { source: '', type: '', url: '' };
                }}>
                {(post.links || []).map((link, i) => {
                  return (
                    <React.Fragment key={i}>
                      <Form.Dropdown
                        required
                        selection
                        label="Source (E.g. Spotify)"
                        options={postLinkSourceOptions}
                        name={`links.${i}.source`}
                        value={link.source || ''}
                        onChange={this.setField}
                      />
                      <Form.Dropdown
                        required
                        selection
                        name={`links.${i}.type`}
                        label="Type"
                        placeholder="Select a type"
                        value={link.type || ''}
                        options={[
                          { text: 'Audio', value: 'audio' },
                          { text: 'Video', value: 'video' },
                        ]}
                        onChange={this.setField}
                      />
                      <Form.Input
                        required
                        label="URL"
                        name={`links.${i}.url`}
                        value={link.url || ''}
                        onChange={this.setField}
                      />
                    </React.Fragment>
                  );
                })}
              </ArrayField>
              <RichTextField
                required
                type="text"
                name="introductionText"
                label="The Story"
                value={post.introductionText || ''}
                onChange={this.setField}
              />
              <RichTextField
                type="text"
                name="bodyText"
                label="Body Text"
                value={post.bodyText || ''}
                onChange={this.setField}
              />
              <UploadsField
                required
                name="thumbnailImage"
                label="Thumbnail Image"
                value={post.thumbnailImage}
                onChange={this.setField}
                onError={(error) => this.setState({ error })}
              />
              <Form.Dropdown
                search
                selection
                multiple
                name="spirits"
                label="Spirits"
                options={Object.keys(spiritTypes).map(t => spiritTypes[t]).flat() || []}
                onChange={this.setField}
                value={post.spirits || []}
              />
              <UploadsField
                single={true}
                name="videoThumbnail"
                label="Video Thumbnail"
                value={post.videoThumbnail || {}}
                onChange={this.setField}
                onError={(error) => this.setState({ error })}
              />
              <UploadsField
                type="video"
                name="videos"
                label="Videos"
                value={post.videos || []}
                onChange={this.setField}
                onError={(error) => this.setState({ error })}
              />
              <Form.Input
                type="text"
                name="cocktailsTitle"
                label="Cocktails Title"
                value={post.cocktailsTitle || ''}
                onChange={this.setField}
              />
              {!this.props.cocktails && (
                <ReferenceField
                  name="cocktails"
                  label="Cocktails"
                  value={post.cocktails || []}
                  path="/1/cocktails/search"
                  placeholder="Search Cocktails"
                  onChange={this.setField}
                />
              )}
              <Form.Input
                type="text"
                name="productsTitle"
                label="Products Title"
                value={post.productsTitle || ''}
                onChange={this.setField}
              />
              <Form.Input
                type="text"
                name="videoPlaylistTitle"
                label="Video Playlist Title"
                value={post.videoPlaylistTitle || ''}
                onChange={this.setField}
              />
              {!this.props.products && (
                <ReferenceField
                  name="products"
                  label="Products"
                  value={post.products || []}
                  path="/1/products/search"
                  placeholder="Search Products"
                  onChange={this.setField}
                />
              )}
              <Form.Input
                type="text"
                name="relatedPostsTitle"
                label="Related Posts Title"
                value={post.relatedPostsTitle || ''}
                onChange={this.setField}
              />
              {!this.props.relatedPosts && (
                <ReferenceField
                  name="relatedPosts"
                  label="Related Posts"
                  value={post.relatedPosts || []}
                  path="/1/posts/search"
                  placeholder="Search Posts"
                  onChange={this.setField}
                />
              )}
              <Form.Dropdown
                selection
                name="visibility"
                label="Visibility"
                value={post.visibility || ''}
                options={visibilityOptions}
                onChange={this.setField}
              />
              <Form.Checkbox
                name="isFeatured"
                label="Is Featured"
                checked={post.isFeatured}
                onChange={this.setCheckedField}
              />
            </Form>
          </AutoFocus>
        </Modal.Content>
        <Modal.Actions>
          <Button
            primary
            form="edit-post"
            loading={loading}
            disabled={loading}
            content={this.isUpdate() ? 'Update' : 'Create'}
          />
        </Modal.Actions>
      </React.Fragment>
    );
  }
}
