import React from 'react';
import { withSession } from 'stores';
import screen from 'helpers/screen';
import { userHasAccess } from 'utils/permissions';

@screen
@withSession
export default class Home extends React.Component {
  componentDidMount() {
    const writeAccess = userHasAccess(this.context.user, {
      endpoint: 'cocktails',
      permission: 'write',
      scope: 'global',
    });
    if (!writeAccess) {
      this.props.history.replace('/logout');
    } else {
      this.props.history.replace('/cocktails');
    }
  }

  render() {
    const { user } = this.context;
    return (
      <div>
        Hello {user.name} ({user.email}) from dashboard
      </div>
    );
  }
}
