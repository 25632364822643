import React, {Fragment} from 'react';
import { Table, Divider, Image } from 'semantic';
import screen from 'helpers/screen';
import Menu from './Menu';

import { formatDateTime } from 'utils/date';
import { urlForUpload } from 'utils/uploads';
import { Link } from "react-router-dom";

@screen
export default class CuratedListOverview extends React.Component {
  render() {
    const { curatedList } = this.props;
    return (
      <React.Fragment>
        <Menu {...this.props} />
        <Divider hidden />
        { curatedList.image &&
          <Image.Group size="small">
            <Image key={curatedList.image.id} src={urlForUpload(curatedList.image)} />
          </Image.Group>
        }

        { curatedList.products.length > 0 &&
          <Fragment>
            <h3>Products</h3>
            <Table celled padded>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell>Name</Table.HeaderCell>
                </Table.Row>
              </Table.Header>

              <Table.Body>
                { curatedList.products.map((product, i) => (
                    <Table.Row key={i}>
                      <Table.Cell>
                        <Link to={`/products/${product.id}`}>{product.name}</Link>
                      </Table.Cell>
                    </Table.Row>
                  )
                )}
              </Table.Body>
            </Table>
          </Fragment>
        }

        { curatedList.cocktails.length > 0 &&
          <Fragment>
            <h3>Cocktails</h3>
            <Table celled padded>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell>Name</Table.HeaderCell>
                </Table.Row>
              </Table.Header>

              <Table.Body>
                { curatedList.cocktails.map((cocktail, i) => (
                    <Table.Row key={i}>
                      <Table.Cell>
                        <Link to={`/cocktails/${cocktail.id}`}>{cocktail.name}</Link>
                      </Table.Cell>
                    </Table.Row>
                  )
                )}
              </Table.Body>
            </Table>
          </Fragment>
        }

        <Table definition>
          <Table.Body>
            <Table.Row>
              <Table.Cell>Title</Table.Cell>
              <Table.Cell>{curatedList.title || 'None'}</Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>Subtitle</Table.Cell>
              <Table.Cell>{curatedList.subtitle || 'None'}</Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>Button Title</Table.Cell>
              <Table.Cell>{curatedList.buttonTitle || 'None'}</Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>Type</Table.Cell>
              <Table.Cell>{curatedList.type || 'None'}</Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>Visibility</Table.Cell>
              <Table.Cell>{curatedList.visibility || 'None'}</Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>Created At</Table.Cell>
              <Table.Cell>{formatDateTime(curatedList.createdAt)}</Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>Updated At</Table.Cell>
              <Table.Cell>{formatDateTime(curatedList.updatedAt)}</Table.Cell>
            </Table.Row>
          </Table.Body>
        </Table>
      </React.Fragment>
    );
  }
}
