import React from 'react';
import { Modal, Form, Button, Message } from 'semantic';
import { request } from 'utils/api';
import AutoFocus from 'components/AutoFocus';
import modal from 'helpers/modal';

import RichTextField from '../components/form-fields/RichText';
import { set } from 'lodash';

@modal
export default class EditMerchant extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      loading: false,
      merchant: props.merchant || {},
    };
  }

  isUpdate() {
    return !!this.props.merchant;
  }

  setField = (evt, { name, value }) => {
    const merchant = { ...this.state.merchant };
    set(merchant, name, value);
    this.setState({
      merchant,
    });
  };

  setNumberField = (evt, { name, value }) => {
    this.setField(evt, { name, value: Number(value) });
  };

  setCheckedField = (evt, { name, checked }) => {
    this.setField(evt, { name, value: checked });
  };

  onSubmit = async () => {
    this.setState({
      loading: true,
    });
    const { merchant } = this.state;

    try {
      if (this.isUpdate()) {
        await request({
          method: 'PATCH',
          path: `/1/merchants/${merchant.id}`,
          body: merchant,
        });
      } else {
        await request({
          method: 'POST',
          path: '/1/merchants',
          body: {
            ...merchant,
          },
        });
      }
      this.props.close();
      this.props.onSave();
    } catch (error) {
      this.setState({
        error,
        loading: false,
      });
    }
  };

  render() {
    const { merchant, loading, error } = this.state;
    return (
      <React.Fragment>
        <Modal.Header>
          {this.isUpdate() ? `Edit "${merchant.name}"` : 'New Merchant'}
        </Modal.Header>
        <Modal.Content>
          <AutoFocus>
            <Form
              noValidate
              id="edit-merchant"
              error={!!error}
              onSubmit={this.onSubmit}>
              {error && <Message error content={error.message} />}
              <Form.Input
                required
                type="text"
                name="name"
                label="Name"
                value={merchant.name || ''}
                onChange={this.setField}
              />
              <Form.Input
                required
                type="text"
                name="squareIdentifier"
                label="Square Identifier"
                value={merchant.squareIdentifier || ''}
                onChange={this.setField}
              />
              <RichTextField
                required
                type="text"
                name="description"
                label="Product Description"
                value={merchant.description || ''}
                onChange={this.setField}
                short={true}
              />
            </Form>
          </AutoFocus>
        </Modal.Content>
        <Modal.Actions>
          <Button
            primary
            form="edit-merchant"
            loading={loading}
            disabled={loading}
            content={this.isUpdate() ? 'Update' : 'Create'}
          />
        </Modal.Actions>
      </React.Fragment>
    );
  }
}
