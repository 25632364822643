import React from 'react';
import { Modal, Form, Button, Message } from 'semantic';
import { request } from 'utils/api';
import AutoFocus from 'components/AutoFocus';
import modal from 'helpers/modal';

@modal
export default class UpdateOrderDeliveryStatus extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      loading: false,
      deliveryStatus: props.deliveryStatus || {},
    };
  }

  setField = (evt, { name, value }) => {
    this.setState({
      deliveryStatus: {
        ...this.state.deliveryStatus,
        [name]: value,
      },
    });
  };

  onSubmit = async () => {
    this.setState({
      loading: true,
    });
    const { order } = this.props;
    const { deliveryStatus } = this.state;

    try {
      await request({
        method: 'POST',
        path: '/1/orders/' + order.id + '/delivery-status',
        body: {
          ...deliveryStatus,
        },
      });
      this.props.close();
      this.props.onSave();
    } catch (error) {
      this.setState({
        error,
        loading: false,
      });
    }
  };

  render() {
    const { deliveryStatus, loading, error } = this.state;
    return (
      <React.Fragment>
        <Modal.Header>Update Delivery Status</Modal.Header>
        <Modal.Content>
          <AutoFocus>
            <Form
              noValidate
              id="edit-order"
              error={!!error}
              onSubmit={this.onSubmit}>
              {error && <Message error content={error.message} />}
              <Form.Dropdown
                required
                selection
                name="eventId"
                label="Status Update"
                value={deliveryStatus.eventId || ''}
                options={[
                  {
                    text: 'Distance: 2 miles away',
                    value: 'distance-2-miles',
                  },
                  {
                    text: 'Distance: 0.3 miles away',
                    value: 'distance-0.3-miles',
                  },
                  {
                    text: 'ETA: 10 minutes',
                    value: 'eta-10-minutes',
                  },
                  {
                    text: 'ETA: 5 minutes',
                    value: 'eta-5-minutes',
                  },
                ]}
                onChange={this.setField}
              />
            </Form>
          </AutoFocus>
        </Modal.Content>
        <Modal.Actions>
          <Button
            primary
            form="edit-order"
            loading={loading}
            disabled={loading}
            content="Save"
          />
        </Modal.Actions>
      </React.Fragment>
    );
  }
}
