import React from 'react';
import { set } from 'lodash';
import { Modal, Form, Button, Message } from 'semantic';
import { request } from 'utils/api';
import AutoFocus from 'components/AutoFocus';
import modal from 'helpers/modal';

import UploadsField from 'components/form-fields/Uploads';
import ArrayField from "../components/form-fields/Array";
import { visibilityOptions } from '../utils/categories';
@modal
export default class EditCuratedList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      loading: false,
      curatedList: props.curatedList || {},
      products: props.products || [],
      cocktails: props.cocktails || []
    };
  }

  isUpdate() {
    return !!this.props.curatedList;
  }

  setField = (evt, { name, value }) => {
    const curatedList = { ...this.state.curatedList };
    if (name === 'image') {
      if (value.length > 0) {
        curatedList.image = value[0];
      }
    } else {
      set(curatedList, name, value);
    }
    this.setState({ curatedList });
  };

  onSubmit = async () => {
    this.setState({
      loading: true,
    });
    const { curatedList } = this.state;

    try {
      if (this.isUpdate()) {
        await request({
          method: 'PATCH',
          path: `/1/curated-lists/${curatedList.id}`,
          body: curatedList,
        });
      } else {
        await request({
          method: 'POST',
          path: '/1/curated-lists',
          body: { ...curatedList },
        });
      }
      this.props.close();
      this.props.onSave();
    } catch (error) {
      this.setState({
        error,
        loading: false,
      });
    }
  };

  render() {
    const { curatedList, cocktails, products, loading, error } = this.state;
    return (
      <React.Fragment>
        <Modal.Header>
          {this.isUpdate() ? `Edit Product List` : 'New Product List'}
        </Modal.Header>
        <Modal.Content>
          <AutoFocus>
            <Form
              noValidate
              id="edit-curated-list"
              error={!!error}
              onSubmit={this.onSubmit}>
              {error && <Message error content={error.message} />}
              <Form.Input
                required
                type="text"
                name="title"
                label="Title"
                value={curatedList.title || ''}
                onChange={this.setField}
              />
              <Form.Input
                required
                type="text"
                name="subtitle"
                label="Subtitle"
                value={curatedList.subtitle || ''}
                onChange={this.setField}
              />
              <Form.Input
                required
                type="text"
                name="buttonTitle"
                label="Button Title"
                value={curatedList.buttonTitle || ''}
                onChange={this.setField}
              />
              <Form.Dropdown
                selection
                required
                name="type"
                label="Type"
                value={curatedList.type || ''}
                options={[
                  {
                    text: "Cocktail",
                    value: "cocktail",
                  },
                  {
                    text: "Product",
                    value: "product",
                  }
                ]}
                onChange={this.setField}
              />
              <ArrayField
                label="Products"
                name="products"
                childLabel="Product"
                onChange={this.setField}
                value={curatedList.products || []}
                getNewField={() => Object({ text: '', value: '' }) }>
                {(curatedList.products || []).map((product, i) => {
                  return (
                    <React.Fragment key={i}>
                      <Form.Dropdown
                        required
                        selection
                        search
                        scrolling
                        name={`products.${i}`}
                        label="Product"
                        placeholder="Select a Product"
                        value={ product.id }
                        options={ products.map(p => Object({ text: p.name, value: p.id })) }
                        onChange={this.setField}
                      />
                    </React.Fragment>
                  );
                })}
              </ArrayField>
              <ArrayField
                label="Cocktails"
                name="cocktails"
                childLabel="Cocktail"
                onChange={this.setField}
                value={curatedList.cocktails || []}
                getNewField={() => Object({ text: '', value: '' }) }>
                {(curatedList.cocktails || []).map((cocktail, i) => {
                  return (
                    <React.Fragment key={i}>
                      <Form.Dropdown
                        required
                        selection
                        search
                        scrolling
                        name={`cocktails.${i}`}
                        label="Cocktail"
                        placeholder="Select a Cocktail"
                        value={ cocktail.id }
                        options={ cocktails.map(c => Object({ text: c.name, value: c.id })) }
                        onChange={this.setField}
                      />
                    </React.Fragment>
                  );
                })}
              </ArrayField>
              <UploadsField
                required
                name="image"
                label="Image"
                value={curatedList.image || []}
                onChange={this.setField}
                onError={(error) => this.setState({ error })}
              />
              <Form.Dropdown
                selection
                required
                name="visibility"
                label="Visibility"
                value={curatedList.visibility || ''}
                options={visibilityOptions}
                onChange={this.setField}
              />
            </Form>
          </AutoFocus>
        </Modal.Content>
        <Modal.Actions>
          <Button
            primary
            form="edit-curated-list"
            loading={loading}
            disabled={loading}
            content={this.isUpdate() ? 'Update' : 'Create'}
          />
        </Modal.Actions>
      </React.Fragment>
    );
  }
}
