import React from 'react';
import { Table, Button, Message, Confirm, Label } from 'semantic';
import { formatDateTime } from 'utils/date';
import { request } from 'utils/api';
import screen from 'helpers/screen';
import { HelpTip, Breadcrumbs, SearchProvider, Layout } from 'components';
import { Link } from 'react-router-dom';

import Filters from 'modals/Filters';
import CreateTestOrder from 'modals/CreateTestOrder';
import { formatCurrency } from 'utils/currency';
import { formatDeliveryStatus, formatPickupStatus } from 'utils/formatting';
import { ENV_NAME } from 'utils/env';

import { withLoadedSession } from 'stores';
import { userHasAccess } from 'utils/permissions';

@screen
@withLoadedSession
export default class OrderList extends React.Component {
  onDataNeeded = async (params) => {
    return await request({
      method: 'POST',
      path: '/1/orders/search',
      body: params,
    });
  };

  render() {
    const writeAccess = userHasAccess(this.context.user, {
      endpoint: 'orders',
      permission: 'write',
      scope: 'global',
    });
    return (
      <SearchProvider onDataNeeded={this.onDataNeeded}>
        {({
          items: orders,
          getSorted,
          setSort,
          filters,
          setFilters,
          reload,
        }) => {
          return (
            <React.Fragment>
              <Breadcrumbs active="Orders" />
              <Layout horizontal center spread>
                <h1>Orders</h1>
                <Layout.Group>
                  <Filters onSave={setFilters} filters={filters}>
                    <Filters.Dropdown
                      search
                      name="status"
                      label="Status"
                      options={[
                        {
                          text: 'draft',
                          value: 'draft',
                        },
                        {
                          text: 'created',
                          value: 'created',
                        },
                        {
                          text: 'failed',
                          value: 'failed',
                        },
                      ]}
                    />
                    <Filters.Dropdown
                      search
                      name="deliveryType"
                      label="Delivery Type"
                      options={[
                        {
                          text: 'pickup',
                          value: 'pickup',
                        },
                        {
                          text: 'shipping',
                          value: 'shipping',
                        },
                        {
                          text: 'courrier',
                          value: 'courrier',
                        },
                      ]}
                    />
                    <Filters.Number name="totalAmount" label="Total Amount" />
                    <Filters.Number
                      name="totalPreTaxAmount"
                      label="Total Pre Tax Amount"
                    />
                    <Filters.Number
                      name="totalTaxAmount"
                      label="Total Tax Amount"
                    />
                    <Filters.Number name="taxRate" label="Tax Rate" />
                    <Filters.Text name="currency" label="Currency" />
                  </Filters>
                  {ENV_NAME === 'development' && (
                    <CreateTestOrder
                      trigger={
                        <Button
                          primary
                          content="New Test Order"
                          icon="plus"
                          disabled={!writeAccess}
                        />
                      }
                      onSave={reload}
                    />
                  )}
                </Layout.Group>
              </Layout>
              {orders.length === 0 ? (
                <Message>No orders created yet</Message>
              ) : (
                <Table celled sortable>
                  <Table.Header>
                    <Table.Row>
                      <Table.HeaderCell>ID</Table.HeaderCell>
                      <Table.HeaderCell>User</Table.HeaderCell>
                      <Table.HeaderCell>Status</Table.HeaderCell>
                      <Table.HeaderCell>Delivery Type</Table.HeaderCell>
                      <Table.HeaderCell>Amount</Table.HeaderCell>
                      <Table.HeaderCell
                        onClick={() => setSort('createdAt')}
                        sorted={getSorted('createdAt')}>
                        Created
                        <HelpTip
                          title="Created"
                          text="This is the date and time the order was created."
                        />
                      </Table.HeaderCell>
                      <Table.HeaderCell textAlign="center">
                        Actions
                      </Table.HeaderCell>
                    </Table.Row>
                  </Table.Header>
                  <Table.Body>
                    {orders.map((order) => {
                      return (
                        <Table.Row key={order.id}>
                          <Table.Cell>
                            <Link to={`/orders/${order.id}`}>{order.id}</Link>
                          </Table.Cell>
                          <Table.Cell>
                            {order.user && (
                              <Link to={`/users/${order.user.id}`}>
                                {order.user.firstName} {order.user.lastName}
                              </Link>
                            )}
                          </Table.Cell>
                          <Table.Cell>
                            {order.deliveryType === 'courrier' &&
                              formatDeliveryStatus(order.deliveryStatus)}
                            {order.deliveryType === 'pickup' &&
                              formatPickupStatus(order.pickupStatus)}
                          </Table.Cell>
                          <Table.Cell>{order.deliveryType}</Table.Cell>
                          <Table.Cell>
                            {formatCurrency(
                              order.totalAmount / 100,
                              order.currency
                            )}
                          </Table.Cell>
                          <Table.Cell>
                            {formatDateTime(order.createdAt)}
                          </Table.Cell>
                          <Table.Cell textAlign="center" singleLine>
                            <Confirm
                              negative
                              confirmButton="Delete"
                              header={`Are you sure you want to delete "${order.id}"?`}
                              content="All data will be permanently deleted"
                              trigger={
                                <Button
                                  basic
                                  icon="trash"
                                  disabled={!writeAccess}
                                />
                              }
                              onConfirm={async () => {
                                await request({
                                  method: 'DELETE',
                                  path: `/1/orders/${order.id}`,
                                });
                                reload();
                              }}
                            />
                            {order.deliveryType === 'courrier' &&
                              !order.onfleetReference && (
                                <Confirm
                                  confirmButton="Resync"
                                  header={`Are you sure you want to sync "${order.id}" to Onfleet?`}
                                  content="A new task will be created and dispatched"
                                  trigger={
                                    <Button
                                      basic
                                      icon="box"
                                      disabled={!writeAccess}
                                    />
                                  }
                                  onConfirm={async () => {
                                    try {
                                      await request({
                                        method: 'POST',
                                        path: `/1/orders/${order.id}/resync/onfleet`,
                                      });
                                      reload();
                                    } catch (error) {
                                      alert(`Error: ${error.message}`);
                                    }
                                  }}
                                />
                              )}
                          </Table.Cell>
                        </Table.Row>
                      );
                    })}
                  </Table.Body>
                </Table>
              )}
            </React.Fragment>
          );
        }}
      </SearchProvider>
    );
  }
}
