import React from 'react';
import { Message } from 'semantic';

import { ApiError } from 'utils/api';
import { CustomError } from 'utils/error';
import { captureError } from 'utils/sentry';

export default class ErrorMessage extends React.Component {
  componentDidMount() {
    this.checkError();
  }

  componentDidUpdate(lastProps) {
    const { error } = this.props;
    if (error !== lastProps.error) {
      this.checkError();
    }
  }

  checkError() {
    const { error } = this.props;
    if (this.canLogError(error)) {
      // Log error for production debugging.
      // eslint-disable-next-line
      console?.error(error);
      captureError(error);
    }
  }

  canLogError(error) {
    if (error instanceof ApiError) {
      return error.status >= 500;
    } else if (error instanceof CustomError) {
      return false;
    } else {
      return !!error;
    }
  }

  render() {
    const { error, message } = this.props;
    if (!error) {
      return null;
    }
    return (
      <Message error size="small">
        <p>{message || error.message}</p>
      </Message>
    );
  }
}
