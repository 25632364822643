import React from 'react';
import { Table, Divider } from 'semantic';
import screen from 'helpers/screen';
import Menu from './Menu';

import { formatDateTime } from 'utils/date';

@screen
export default class SurveyQuestionOverview extends React.Component {
  render() {
    const question = this.props.questionObject;
    return (
      <React.Fragment>
        <Menu question={question} onSave={this.props.onSave} />
        <Divider hidden />
        <Table definition>
          <Table.Body>
            <Table.Row>
              <Table.Cell>Type</Table.Cell>
              <Table.Cell>{question.type || ''}</Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>Question</Table.Cell>
              <Table.Cell>{question.questionText}</Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>Visibility</Table.Cell>
              <Table.Cell>{question.theme || 'None'}</Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>Responses</Table.Cell>
              <Table.Cell>{question.isFeatured ? 'Yes' : 'No'}</Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>Created At</Table.Cell>
              <Table.Cell>{formatDateTime(question.createdAt)}</Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell>Updated At</Table.Cell>
              <Table.Cell>{formatDateTime(question.updatedAt)}</Table.Cell>
            </Table.Row>
          </Table.Body>
        </Table>

        <h3>Available Responses</h3>
        <Table celled padded>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>Response</Table.HeaderCell>
              <Table.HeaderCell>Sub Text</Table.HeaderCell>
            </Table.Row>
          </Table.Header>

          <Table.Body>
            { question.responses.map((response, i) => {
              const { responseText, subText } = response;
              return (
                <Table.Row key={i}>
                  <Table.Cell>{responseText}</Table.Cell>
                  <Table.Cell>{subText}</Table.Cell>
                </Table.Row>
              );
            })}
          </Table.Body>
        </Table>
      </React.Fragment>
    );
  }
}
