import { Form, Grid, Select, Message } from 'semantic';
import AutoFocus from '../../../components/AutoFocus';
import React, { useState } from 'react';
import { request } from '../../../utils/api';
import ReferenceField from 'components/form-fields/Reference';

export default (props) => {
  const { addMode, product } = props;
  const [variant, setVariant] = useState(props.variant || {});
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState();

  const setField = (event, { name, value }) => {
    setVariant({ ...variant, [name]: name === 'image' ? value[0] : value });
  };

  const onUpdate = async () => {
    setLoading(true);

    if (addMode) {
      await request({
        method: 'POST',
        path: `/1/product-variants`,
        body: { ...variant, product: product.id },
      });
    } else {
      await request({
        method: 'PATCH',
        path: `/1/product-variants/${variant.id}`,
        body: variant,
      });
    }

    await props.onUpdate();

    setLoading(false);
  };

  const onDelete = async () => {
    setLoading(true);
    try {
      await request({
        method: 'DELETE',
        path: `/1/product-variants/${variant.id}`,
      });
      await props.onDelete();
      setLoading(false);
    } catch (error) {
      alert(error.message);
    }
  };

  return (
    <AutoFocus id={variant.id}>
      <Form noValidate id="product-variants" onSubmit={onUpdate} error={error}>
        {error && <Message error content={error.message} />}
        <Form.Input
          name="name"
          label="Name"
          value={variant.name}
          onChange={setField}
        />
        <Form.Input
          name="unitPrice"
          label="Unit Price"
          value={variant.unitPrice}
          type="number"
          onChange={setField}
          required
        />
        <Form.Input
          name="currency"
          label="Currency"
          value={variant.currency || 'usd'}
          placeholder="usd"
          onChange={setField}
          required
        />
        <Form.Input
          name="sku"
          label="SKU"
          value={variant.sku}
          onChange={setField}
          required
        />
        <Form.Group widths="equal">
          <Form.Input
            name="liquidAmount"
            label="Liquid Amount"
            value={variant.liquidAmount}
            type="number"
            onChange={setField}
            required
          />
          <Form.Field
            name="liquidAmountUnit"
            label="Liquid Amount Unit"
            control={Select}
            value={variant.liquidAmountUnit}
            options={[
              { key: 'oz', text: 'oz', value: 'oz' },
              { key: 'ml', text: 'ml', value: 'ml' },
              { key: 'l', text: 'l', value: 'l' },
            ]}
            onChange={setField}
            required
          />

          <Form.Input
            type="number"
            name="abvPercentage"
            label="ABV Percentage"
            value={variant.abvPercentage}
            onChange={setField}
          />
        </Form.Group>

        {variant.merchant && (
          <ReferenceField
            name="merchant"
            label="Merchant"
            value={variant.merchant || null}
            path="/1/merchants/search"
            placeholder="Search Merchants"
            readOnly
            disabled
          />
        )}
        <Grid>
          <Grid.Column width={2}>
            <Form.Button primary type="submit" loading={loading}>
              Update
            </Form.Button>
          </Grid.Column>
          {addMode !== true ? (
            <Grid.Column width={2}>
              <Form.Button negative onClick={onDelete}>
                Delete
              </Form.Button>
            </Grid.Column>
          ) : null}
        </Grid>
      </Form>
    </AutoFocus>
  );
};
