import React from 'react';
import { Modal, Form, Button, Message } from 'semantic';
import { request } from 'utils/api';
import AutoFocus from 'components/AutoFocus';
import modal from 'helpers/modal';
import ReferenceField from 'components/form-fields/Reference';

@modal
export default class EditOrder extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      loading: false,
      order: props.order || {
        deliveryType: 'courrier',
      },
    };
  }

  isUpdate() {
    return !!this.props.order;
  }

  setField = (evt, { name, value }) => {
    this.setState({
      order: {
        ...this.state.order,
        [name]: value,
      },
    });
  };

  setNumberField = (evt, { name, value }) => {
    this.setField(evt, { name, value: Number(value) });
  };

  setCheckedField = (evt, { name, checked }) => {
    this.setField(evt, { name, value: checked });
  };

  onSubmit = async () => {
    this.setState({
      loading: true,
    });
    const { order } = this.state;

    try {
      await request({
        method: 'POST',
        path: '/1/cart-items/clear',
        body: {},
      });
      for (const product of order.products) {
        await request({
          method: 'POST',
          path: '/1/cart-items',
          body: {
            product: product.id,
            productVariant: product.variants[0].id,
            quantity: 1,
          },
        });
      }
      const { data: paymentMethods } = await request({
        method: 'POST',
        path: '/1/payment-methods/search',
        body: {},
      });
      let paymentMethod = paymentMethods.filter(
        (p) => p.name === 'admin-test'
      )[0];
      if (!paymentMethod) {
        const { data: newPaymentMethod } = await request({
          method: 'POST',
          path: '/1/payment-methods',
          body: {
            name: 'admin-test',
            paymentToken: 'cnon:card-nonce-ok',
            brandName: 'visa',
            lastFourDigits: '1111',
            expirationMonth: 12,
            expirationYear: 2027,
          },
        });
        paymentMethod = newPaymentMethod;
      }

      const { data: shippingAddresses } = await request({
        method: 'POST',
        path: '/1/shipping-addresses/search',
        body: {},
      });
      const body = {
        paymentMethod: paymentMethod.id,
        comment: 'Test order from admin UI',
        deliveryType: order.deliveryType,
        shippingAddress: shippingAddresses[0].address,
        pickupLocation: order.pickupLocation?.id,
      };
      await request({
        method: 'POST',
        path: '/1/orders/mine',
        body,
      });
      this.props.close();
      this.props.onSave();
    } catch (error) {
      this.setState({
        error,
        loading: false,
      });
    }
  };

  render() {
    const { order, loading, error } = this.state;
    return (
      <React.Fragment>
        <Modal.Header>Create Test Order</Modal.Header>
        <Modal.Content>
          <Form
            noValidate
            id="create-test-order"
            error={!!error}
            onSubmit={this.onSubmit}>
            {error && <Message error content={error.message} />}
            <ReferenceField
              name="products"
              label="Products"
              value={order.products || []}
              path="/1/products/search"
              placeholder="Search Products"
              onChange={this.setField}
            />
            <Form.Dropdown
              selection
              name="deliveryType"
              label="Delivery Type"
              value={order.deliveryType || ''}
              options={[
                {
                  text: 'Pickup',
                  value: 'pickup',
                },
                {
                  text: 'Shipping',
                  value: 'shipping',
                },
                {
                  text: 'Courrier',
                  value: 'courrier',
                },
              ]}
              onChange={this.setField}
            />
            {order.deliveryType === 'pickup' && (
              <ReferenceField
                name="pickupLocation"
                label="Pickup Location"
                value={order.pickupLocation || null}
                path="/1/locations/search"
                placeholder="Search Locations"
                onChange={this.setField}
              />
            )}
          </Form>
        </Modal.Content>
        <Modal.Actions>
          <Button
            primary
            form="create-test-order"
            loading={loading}
            disabled={loading}
            content={'Create'}
          />
        </Modal.Actions>
      </React.Fragment>
    );
  }
}
