import React from 'react';
import { Table, Label, Button } from 'semantic';
import moment from 'moment-timezone';

import { formatDeliveryStatus } from 'utils/formatting';

export default class CourrierStatus extends React.Component {
  render() {
    const { order } = this.props;
    const { onfleetReference, deliveryStatus, deliveryStatusDetails } = order;
    return (
      <React.Fragment>
        <Table definition collapsing>
          <Table.Body>
            <Table.Row>
              <Table.Cell>Delivery Status</Table.Cell>
              <Table.Cell>{formatDeliveryStatus(deliveryStatus)}</Table.Cell>
            </Table.Row>
            {deliveryStatusDetails && (
              <React.Fragment>
                {deliveryStatusDetails.distance && (
                  <Table.Row>
                    <Table.Cell>Last known distance</Table.Cell>
                    <Table.Cell>
                      {(deliveryStatusDetails.distance / 1600).toFixed(2)} miles
                    </Table.Cell>
                  </Table.Row>
                )}
                {deliveryStatusDetails.etaSeconds && (
                  <Table.Row>
                    <Table.Cell>Last known ETA</Table.Cell>
                    <Table.Cell>
                      {(deliveryStatusDetails.etaSeconds / 60).toFixed(0)}{' '}
                      minutes
                    </Table.Cell>
                  </Table.Row>
                )}
                <Table.Row>
                  <Table.Cell>Estimated Arrival Time</Table.Cell>
                  <Table.Cell>
                    {deliveryStatusDetails.estimatedArrivalTime
                      ? moment(
                          deliveryStatusDetails.estimatedArrivalTime
                        ).fromNow()
                      : '-'}
                  </Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell>Estimated Completion Time</Table.Cell>
                  <Table.Cell>
                    {deliveryStatusDetails.estimatedCompletionTime
                      ? moment(
                          deliveryStatusDetails.estimatedCompletionTime
                        ).fromNow()
                      : '-'}
                  </Table.Cell>
                </Table.Row>

                <Table.Row>
                  <Table.Cell>Last Update</Table.Cell>
                  <Table.Cell>
                    {moment(deliveryStatusDetails.lastUpdatedAt).fromNow()}
                  </Table.Cell>
                </Table.Row>
              </React.Fragment>
            )}
            <Table.Row>
              <Table.Cell>Actions</Table.Cell>
              <Table.Cell>
                {deliveryStatusDetails && (
                  <Button
                    basic
                    as="a"
                    icon="truck"
                    href={`https://onfleet.com/dashboard#/manage?open=worker&workerId=${deliveryStatusDetails.worker}`}
                    target="_blank"
                    content="View Worker"
                    noreferer
                  />
                )}

                <Button
                  basic
                  as="a"
                  icon="map-marked"
                  href={`https://onfleet.com/dashboard#/manage?taskEdit=false&open=task&taskId=${onfleetReference}`}
                  target="_blank"
                  content="View Task"
                  noreferer
                />
              </Table.Cell>
            </Table.Row>
          </Table.Body>
        </Table>
      </React.Fragment>
    );
  }
}
