import React from 'react';
import { Table, Button, Message, Confirm, Popup, Label } from 'semantic';
import { formatDateTime } from 'utils/date';
import { request } from 'utils/api';
import screen from 'helpers/screen';
import { HelpTip, Breadcrumbs, SearchProvider, Layout } from 'components';
import { urlForUpload } from 'utils/uploads';
import { Image } from 'semantic';
import { Link } from 'react-router-dom';

import Filters from 'modals/Filters';
import EditProduct from 'modals/EditProduct';
import { renderProductFlags } from 'utils/categories';
import { withLoadedSession } from 'stores';
import { userHasAccess } from 'utils/permissions';

@screen
@withLoadedSession
export default class ProductList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  onDataNeeded = async (params) => {
    return await request({
      method: 'POST',
      path: '/1/products/search',
      body: {
        ...params,
        includeDisabled: true,
        includeUpsell: true,
      },
    });
  };

  render() {
    const writeAccess = userHasAccess(this.context.user, {
      endpoint: 'products',
      permission: 'write',
      scope: 'global',
    });

    return (
      <SearchProvider onDataNeeded={this.onDataNeeded}>
        {({
          items: products,
          getSorted,
          setSort,
          filters,
          setFilters,
          reload,
        }) => {
          return (
            <React.Fragment>
              <Breadcrumbs active="Products" />
              <Layout horizontal center spread>
                <h1>Products</h1>
                <Layout.Group>
                  <Filters onSave={setFilters} filters={filters}>
                    <Filters.Text name="keyword" label="Keywords" />
                    <Filters.Text name="name" label="Name" />
                    <Filters.Text name="brandName" label="Brand Name" />
                    <Filters.Text name="spirit" label="Spirit" />
                    <Filters.Text name="spirit" label="Spirit" />
                    <Filters.Text name="category" label="Category" />
                    <Filters.Dropdown
                      search
                      name="subType"
                      label="Sub Type"
                      options={window.meta.subTypes}
                    />
                    <Filters.Dropdown
                      search
                      name="visibility"
                      label="Visibility"
                      options={[
                        {
                          text: 'draft',
                          value: 'draft',
                        },
                        {
                          text: 'published',
                          value: 'published',
                        },
                      ]}
                    />
                    <Filters.Checkbox name="isFeatured" label="Is Featured" />
                    <Filters.Checkbox
                      name="isDisabled"
                      label="Is Disabled for Online Sales"
                    />
                  </Filters>
                  {false && (
                    <EditProduct
                      trigger={
                        <Button
                          primary
                          content="New Product"
                          icon="plus"
                          disabled={!writeAccess}
                        />
                      }
                      onSave={reload}
                    />
                  )}
                </Layout.Group>
              </Layout>
              {products.length === 0 ? (
                <Message>No products created yet</Message>
              ) : (
                <Table celled sortable>
                  <Table.Header>
                    <Table.Row>
                      <Table.HeaderCell
                        sorted={getSorted('name')}
                        onClick={() => setSort('name')}>
                        Name
                      </Table.HeaderCell>
                      <Table.HeaderCell width={2}>Images</Table.HeaderCell>
                      <Table.HeaderCell width={1}>Visibility</Table.HeaderCell>
                      <Table.HeaderCell width={2}>Brand Name</Table.HeaderCell>
                      <Table.HeaderCell
                        onClick={() => setSort('createdAt')}
                        sorted={getSorted('createdAt')}
                        width={3}>
                        Created
                        <HelpTip
                          title="Created"
                          text="This is the date and time the product was created."
                        />
                      </Table.HeaderCell>
                      <Table.HeaderCell textAlign="center" width={3}>
                        Actions
                      </Table.HeaderCell>
                    </Table.Row>
                  </Table.Header>
                  <Table.Body>
                    {products.map((product) => {
                      return (
                        <Table.Row key={product.id}>
                          <Table.Cell>
                            <div style={{ float: 'right', marginTop: '-5px' }}>
                              {renderProductFlags(product)}
                            </div>
                            <Link to={`/products/${product.id}`}>
                              {product.name}
                            </Link>
                            {product.isDisabled && (
                              <Label
                                content="Offline Only"
                                style={{ float: 'right' }}
                              />
                            )}
                          </Table.Cell>
                          <Table.Cell>
                            {product.images[0] && (
                              <Image
                                style={{ maxHeight: '100px' }}
                                size="tiny"
                                src={urlForUpload(product.images[0], true)}
                              />
                            )}
                          </Table.Cell>
                          <Table.Cell>{product.visibility}</Table.Cell>
                          <Table.Cell>{product.brandName}</Table.Cell>
                          <Table.Cell>
                            {formatDateTime(product.createdAt)}
                          </Table.Cell>
                          <Table.Cell textAlign="center" singleLine>
                            <EditProduct
                              products={products}
                              product={product}
                              trigger={
                                <Button
                                  basic
                                  icon="edit"
                                  disabled={!writeAccess}
                                />
                              }
                              onSave={reload}
                            />
                            <Confirm
                              negative
                              confirmButton="Delete"
                              header={`Are you sure you want to delete "${product.name}"?`}
                              content="All data will be permanently deleted"
                              trigger={
                                <Button
                                  basic
                                  icon="trash"
                                  disabled={!writeAccess}
                                />
                              }
                              onConfirm={async () => {
                                try {
                                  await request({
                                    method: 'DELETE',
                                    path: `/1/products/${product.id}`,
                                  });
                                  reload();
                                } catch (error) {
                                  alert(error.message);
                                }
                              }}
                            />
                          </Table.Cell>
                        </Table.Row>
                      );
                    })}
                  </Table.Body>
                </Table>
              )}
            </React.Fragment>
          );
        }}
      </SearchProvider>
    );
  }
}
