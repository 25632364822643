import React from 'react';
import { Link } from 'react-router-dom';
import { Menu, Button } from 'semantic';
import { NavLink } from 'react-router-dom';
import { Breadcrumbs, Layout } from 'components';

import EditCuratedList from 'modals/EditCuratedList';

export default ({ curatedList, products, cocktails, onSave }) => {
  return (
    <React.Fragment>
      <Breadcrumbs link={<Link to="/curated-lists">Curated Lists</Link>} active={curatedList.title} />
      <Layout horizontal center spread>
        <h1>{curatedList.title}</h1>
        <Layout.Group>
          <EditCuratedList
            curatedList={curatedList}
            products={products}
            cocktails={cocktails}
            onSave={onSave}
            trigger={<Button primary icon="pen" content="Update" />}
          />
        </Layout.Group>
      </Layout>
      <Menu pointing secondary>
        <Menu.Item name="Overview" to={`/curated-lists/${curatedList.id}`} as={NavLink} exact />
      </Menu>
    </React.Fragment>
  );
};
