import React from 'react';
import { Form, Segment, Divider, Button, Icon } from 'semantic';

export default class ArrayField extends React.Component {
  onAddClick = (evt) => {
    const { value, ...rest } = this.props;
    this.props.onChange(evt, {
      ...rest,
      value: [...value, this.props.getNewField()],
    });
  };

  removeByIndex = (evt, index) => {
    const { value, ...rest } = this.props;
    this.props.onChange(evt, {
      ...rest,
      value: value.filter((el, i) => i !== index),
    });
  };

  render() {
    const { label, childLabel, children } = this.props;
    return (
      <Form.Field>
        <label>{label}</label>
        <Segment style={{ padding: '20px' }}>
          {children.map((child, i) => {
            return (
              <React.Fragment key={i}>
                <h4>
                  {childLabel} {i + 1}
                  <Icon
                    link
                    color="red"
                    size="small"
                    name="trash"
                    style={{ marginLeft: '10px' }}
                    onClick={(evt) => {
                      this.removeByIndex(evt, i);
                    }}
                  />
                </h4>
                <div
                  style={{
                    marginBottom: '20px',
                  }}>
                  {child}
                </div>
                <Divider />
              </React.Fragment>
            );
          })}

          <Button type="button" size="tiny" onClick={this.onAddClick} primary>
            Add
          </Button>
        </Segment>
      </Form.Field>
    );
  }
}

ArrayField.defaultProps = {
  getNewField: () => '',
};
